import { EnterElement, ScaleLinear, Selection, curveBasis, line, range } from "d3";
import { D3OneToOneRenderable } from "./D3OneToOneRenderable";
import { ReactCallbacks } from "../../Types/ReactCallbacks";

export type D3ParabolaConfig = {
    yScale: ScaleLinear<any, any, any>
    xScale: ScaleLinear<any, any, any>
    coefficients: (number | null)[]
    clipPathId: string
    isDashed?: boolean
    color?: string
    transitionDuration?: number
    applyInverseFisher?: boolean
}

export class D3Parabola extends D3OneToOneRenderable<SVGGElement, SVGGElement, D3ParabolaConfig> {
    private points: [number, number][] = []

    constructor(root: SVGGElement, config: D3ParabolaConfig, reactCallbacks: ReactCallbacks<any>) {
        super(root, config, "d3-parabola", reactCallbacks)
        this.updateDerivedState()
        this.render()
    }

    public getMinimumPoint = (): [number, number] => {
        const [a, b, ] = this.config.coefficients
        const minX = -(b ?? 0) / (2 * (a ?? 0))
        const minY = this.evaluate(minX)
        return [minX, minY]
    }

    public evaluate = (x: number): number => {
        const [a, b, c] = this.config.coefficients

        if (Number.isNaN(a) || Number.isNaN(b) || Number.isNaN(c)) {
            return NaN
        }

        let y = ((a ?? 0) * x * x) + ((b ?? 0) * x) + (c ?? 0)

        if (this.config.applyInverseFisher) {
            y = Math.tanh(y)
        }

        return y
    }

    private getLine = (points: [number, number][]) => {
        return line()
            .x(point => this.config.xScale(point[0]))
            .y(point => this.config.yScale(point[1]))
            .curve(curveBasis)
            (points)
    }

    protected enter(newElements: Selection<EnterElement, D3ParabolaConfig, any, any>): Selection<SVGGElement, D3ParabolaConfig, SVGGElement, any> {
        const parabola = newElements
            .append("g")
            .attr("class", this.className)
            .attr("clip-path", `url(#${this.config.clipPathId})`)

        parabola
            .append("path")
            .data([this.points])
            .attr("fill", "none")
            .attr("stroke", this.config.color ? this.config.color : "black")
            .attr("stroke-width", 1)
            .attr("stroke-dasharray", this.config.isDashed ? '5,5' : null)
            .attr("d", this.getLine(this.points))

        return parabola
    }

    protected update(updatedElements: Selection<SVGGElement, D3ParabolaConfig, any, any>): Selection<SVGGElement, D3ParabolaConfig, SVGGElement, any> {
        const parabola = updatedElements

        parabola.select("path")
            .data([this.points])
            .transition()
            .duration(this.config.transitionDuration ?? 0)
            .attr("d", this.getLine(this.points))
        
        return parabola
    }

    protected updateDerivedState = () => {
        const [start, end] = this.config.xScale.domain()
        const step = (end - start) / 100
        this.points = range(start, end + step, step).map(x => ([x, this.evaluate(x)]))
    }
}