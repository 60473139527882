import { TraceDataConfig } from "../Types/Trace"

export type TraceData = TimeSeriesData | CompositeTimeSeriesData

export type TimeSeriesData = {
    times: (number | undefined)[]
    data: Float32Array
}

export type CompositeTimeSeriesData = {
    times: (number | undefined)[]
    data: Float32Array[]
}

export function getSignal(traceData: TraceData, config: TraceDataConfig): Float32Array {
    if (config.isCompositePart && isCompositeTimeSeriesData(traceData)) {
        return traceData.data[config.compositeIndex]
    }

    return (traceData as TimeSeriesData).data
}

export function getTimeSeriesData(traceData: TimeSeriesData | CompositeTimeSeriesData, config: TraceDataConfig): TimeSeriesData {
    if (config.isCompositePart && isCompositeTimeSeriesData(traceData)) {
        return { times: traceData.times, data: traceData.data[config.compositeIndex] }
    }

    return traceData as TimeSeriesData
}

export function getGapIndexes(gapIndexes: number[] | number[][], config: TraceDataConfig): number[] {
    if (config.isCompositePart && Array.isArray(gapIndexes[0])) {
        return gapIndexes[config.compositeIndex] as number[]
    }

    return gapIndexes as number[]
}

function isCompositeTimeSeriesData(traceData: TimeSeriesData | CompositeTimeSeriesData): traceData is CompositeTimeSeriesData {
    return Array.isArray(traceData.data[0]) || traceData.data[0] instanceof Float32Array;
}
