import { TimeSeriesPageQueue } from "./TimeSeriesDataQueue"
import { ScaleTime, scaleTime } from "d3"
import { Page } from "./Page"
import { debounce, range, throttle } from "lodash"
import { zonedTimeToUtc } from "date-fns-tz"
import { ModalityDataSource } from "../Types/ModalityDataSource"
import { Socket } from "socket.io-client"

export interface TimeSeriesPageManagerConfig {
	patientId: string
	windowId: string
	modalityDataSources: ModalityDataSource[]
	viewScale: ScaleTime<any, any, any>
	fileScale: ScaleTime<any, any, any>
	timeZone: string
	patientIsAdmitted: boolean
}

export abstract class TimeSeriesPageManager<PageType extends Page<any>, ConfigType extends TimeSeriesPageManagerConfig = TimeSeriesPageManagerConfig> {
	abstract getPageMaker(): () => PageType

	public timeZoneOffsetMs: number

	protected pageQueue = new TimeSeriesPageQueue<PageType>()
	protected config: ConfigType
	protected pages = new Map<number, PageType>()
	protected getDataQuerySocket: null | ((id?: string) => Socket) = null
	
	private pagesInView: Array<PageType | undefined> = Array(2)
	private loadBuffer: Array<PageType> = []
	private isLoadingEnabled: boolean = true

	protected onPageUnloaded: (page: PageType) => void = () => {}

	constructor() {
		const defaultConfig: TimeSeriesPageManagerConfig = {
			patientId: "",
			windowId: "",
			modalityDataSources: [],
			viewScale: scaleTime(),
			fileScale: scaleTime(),
			timeZone: "America/New_York",
			patientIsAdmitted: true
		}

		this.config = defaultConfig as ConfigType
		this.timeZoneOffsetMs = this.getTimeZoneOffsetMs()
	}

	public setDataQuerySocketAccessor(getDataQuerySocket: (id?: string) => Socket) {
		this.getDataQuerySocket = getDataQuerySocket
	}

	public update(config: ConfigType, onPageLoaded: (page: PageType) => void, onPageUnloaded: (page: PageType) => void) {
		this.pageQueue.onSuccess = (page: PageType) => onPageLoaded(page)
		this.onPageUnloaded = (page: PageType) => onPageUnloaded(page)
		this.config = config
		this.updateDerivedState()
		this.requestLoad()
	}

	public unloadAllPages() {
		this.pages.forEach(page => this.removePage(page))
		this.pages.clear()
	}

	public resetPages() {
		this.unloadAllPages()
		this.populateLoadBuffer()
	}

	public clearRenderCacheForAllPages() {
		this.pages.forEach(page => page.clearRenderCache())
	}

	public setLoadingEnabled(enabled: boolean) {
		this.isLoadingEnabled = enabled
	}

	protected getNumberOfCachedPages() {
		return 60
	}

	protected updateDerivedState() {
		const width = this.config.viewScale.range()[1]
		this.timeZoneOffsetMs = this.getTimeZoneOffsetMs()
		this.pages.forEach(page => this.updatePageProperties(page, page.startTime, page.endTime, width, this.timeZoneOffsetMs))
	}

	private updateQueue = () => {
		// Loading can be disabled to prevent a race condition where the debounced update causes pages to reconnect the socket after Data Review closes.
		// This problem is more likely to happen in Live Review mode.
		if (!this.isLoadingEnabled) {
			console.log("Loading is disabled!", this)
			return
		}

		this.updateLoadBuffer()
		const notLoadedPages = this.loadBuffer.filter(page => page.needsToBeLoaded())
		this.pageQueue.push(notLoadedPages)
		this.pageQueue.unloadOutside(this.loadBuffer)
	}

	// throttle allows us to update regularly, but control how many.
	private throttledUpdate = throttle(this.updateQueue, 100)

	// debounce makes sure that a final load gets called when we stop requesting loads.
	private debouncedUpdate = debounce(this.updateQueue, 200)

	public requestLoad = () => {
		this.throttledUpdate()
		this.debouncedUpdate()
	}

	public clearQueue = () => {
		this.pageQueue.clear()
	}

	public clearQueueAndLoad() {
		this.clearQueue()
		this.requestLoad()
	}

	private clampTime(timestamp: number) {
		const [minTime, maxTime] = this.config.fileScale.domain()
		return Math.min(maxTime.getTime(), Math.max(minTime.getTime(), timestamp))
	}

	public getUnloadedRegions(): Array<[number, number]> {
		const regions: [number, number][] = []
		const minTime = this.config.fileScale.domain()[0].getTime()
		const maxTime = this.config.fileScale.domain()[1].getTime()

		const pagesInRange = this.loadBuffer
			.filter(page => page.endTime > minTime && page.startTime < maxTime)
			.sort((a, b) => (a.startTime < b.startTime ? -1 : 1))

		if (pagesInRange.length === 0) {
			return [[minTime, maxTime]]
		}

		// If there is a gap between the min time and the first page start time, mark it as unloaded
		if (pagesInRange[0].startTime > minTime) {
			regions.push([minTime, pagesInRange[0].startTime])
		}

		// Accumulate unloaded regions
		pagesInRange.forEach(page => {
			if (page.loaded || page.renderCache.size > 0) {
				return
			}
			
			const lastRegion = regions[regions.length - 1]
			const startTime = this.clampTime(page.startTime)
			const endTime = this.clampTime(page.endTime)

			// Merge with previous if adjacent
			if (lastRegion && lastRegion[1] === startTime) {
				lastRegion[1] = endTime
			} else {
				regions.push([startTime, endTime])
			}
		})

		// If there is a gap between the max time and the last page end time, mark it as unloaded
		const lastPageEndTime = pagesInRange[pagesInRange.length - 1].endTime

		if (lastPageEndTime < maxTime) {
			const lastRegion = regions[regions.length - 1]

			if (lastRegion && lastRegion[1] === lastPageEndTime) {
				lastRegion[1] = maxTime
			} else {
				regions.push([lastPageEndTime, maxTime])
			}
		}

		return regions
	}

	public getPagesInView(): (PageType | undefined)[] {
		const viewDuration = this.getViewDuration()
		const currentPageStartTime = this.currentPageStartTime()
		this.pagesInView[0] = this.pages.get(currentPageStartTime)
		this.pagesInView[1] = this.pages.get(currentPageStartTime + viewDuration)

		return this.pagesInView
	}

	public getPreviousPage(page: PageType | undefined): PageType | undefined {
		if (!page) {
			return undefined
		}

		const viewDuration = this.getViewDuration()
		return this.pages.get(this.currentPageStartTime() - viewDuration)
	}

	public getNextPage(page: PageType | undefined): PageType | undefined {
		if (!page) {
			return undefined
		}

		return this.pages.get(page.endTime)
	}

	public getAllLoadedPages(): Map<number, PageType> {
		return this.pageQueue.allLoadedPages
	}

	public onEndDateUpdated(newEndTime: number): void {

		this.pages.forEach(page => {
			if (page.startTime < newEndTime) { // filter out the pages that start in the future.
				page.updateMaxReadTime(newEndTime)
			}
		})

		this.updateQueue()
	}

	protected currentPageStartTime(): number {
		const fileStartTime = this.config.fileScale.domain()[0].getTime()
		const viewStartTime = this.config.viewScale.domain()[0].getTime()
		const viewDuration = this.config.viewScale.domain()[1].getTime() - viewStartTime
		const pageIndex = Math.max(Math.floor((viewStartTime - fileStartTime) / viewDuration), 0)
		return pageIndex * viewDuration + fileStartTime
	}

	private getViewDuration(): number {
		const viewStartTime = this.config.viewScale.domain()[0].getTime()
		return this.config.viewScale.domain()[1].getTime() - viewStartTime
	}

	protected removePage(page: PageType) {
		page.unload()
		this.onPageUnloaded(page)
		this.pages.delete(page.startTime)
	}

	// Get all the pages that are relevant, in the order that we want to load them.
	private updateLoadBuffer() {
		if (this.pages.size === 0) {
			this.loadBuffer = []
		}

		this.pruneOutsideLoadBuffer()
		this.populateLoadBuffer()

		const viewStartTime = this.config.viewScale.domain()[0].getTime()
		const loadBuffer = Array.from(this.pages.values())

		// Sort the pages by their distance to the current view start time
		this.loadBuffer = loadBuffer.sort((page1, page2) => (Math.abs(viewStartTime - page1.startTime) < Math.abs(viewStartTime - page2.startTime) ? -1 : 1))
	}

	protected getTimeZoneOffsetMs() {
		const fileStartTime = this.config.fileScale.domain()[0].getTime()
		return zonedTimeToUtc(fileStartTime, this.config.timeZone).getTime() - fileStartTime
	}

	private populateLoadBuffer() {
		const [loadBufferStart, loadBufferEnd] = this.getLoadBufferStartAndEnd()
		const viewStartTime = this.config.viewScale.domain()[0].getTime()
		const viewEndTime = this.config.viewScale.domain()[1].getTime()
		const viewDuration = viewEndTime - viewStartTime
		const recordingEndTime = this.config.fileScale.domain()[1].getTime()
		const recordingStartTime = this.config.fileScale.domain()[0].getTime()
		const pageWidthInPixels = this.config.viewScale.range()[1]
		const timeZoneOffsetMs = this.getTimeZoneOffsetMs()

		if (pageWidthInPixels < 0) {
			return
		}

		const pageStartTimes = range(loadBufferStart, loadBufferEnd, viewDuration)
		const pageConstructor = this.getPageMaker()

		pageStartTimes.forEach(startTime => {
			if (this.pages.has(startTime) || startTime + viewDuration <= recordingStartTime || startTime > recordingEndTime) {
				return
			}

			const page = pageConstructor()
			this.updatePageProperties(page, startTime, startTime + viewDuration, pageWidthInPixels, timeZoneOffsetMs)
			this.pages.set(startTime, page)
		})
	}

	protected updatePageProperties(page: PageType, startTime: number, endTime: number, width: number, timeZoneOffsetMs: number) {
		page.updateProperties({
			startTime,
			endTime,
			width,
			socketId: this.config.windowId,
			patientId: this.config.patientId,
			timeZoneOffsetMs: timeZoneOffsetMs,
			modalityDataSources: this.config.modalityDataSources,
			patientIsAdmitted: this.config.patientIsAdmitted,
			maxReadTime: this.config.fileScale.domain()[1].getTime(),
			getDataQuerySocket: this.getDataQuerySocket
		})
	}

	private pruneOutsideLoadBuffer() {
		const pagesToDelete: PageType[] = []
		const [loadBufferStart, loadBufferEnd] = this.getLoadBufferStartAndEnd()

		// Find the pages that are out of range
		this.pages.forEach(page => {
			if (page.startTime < loadBufferStart || page.endTime > loadBufferEnd) {
				pagesToDelete.push(page)
			}
		})

		// remove them after done iterating over pages
		pagesToDelete.forEach(page => this.removePage(page))
	}

	protected getLoadBufferStartAndEnd() {
		const viewStartTime = this.config.viewScale.domain()[0].getTime()
		const viewEndTime = this.config.viewScale.domain()[1].getTime()
		const viewDuration = viewEndTime - viewStartTime
		const halfNeighborhoodSize = Math.floor(this.getNumberOfCachedPages() / 2)
		const currentPageStartTime = this.currentPageStartTime()
		const neighborhoodStartTime = currentPageStartTime - halfNeighborhoodSize * viewDuration
		const neighborhoodEndTime = currentPageStartTime + viewDuration + halfNeighborhoodSize * viewDuration

		return [neighborhoodStartTime, neighborhoodEndTime]
	}
}
