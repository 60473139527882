import { ENDPOINTS } from "./APIEndpoints"

/**
 * @name DataQuery
 * 
 * @description Singleton holding functions that directly interface with a Moberg Data Query instance. 
 * 
 * To operate, it needs to be initialized with necessary variables:
 * - USER_TOKEN: The access token (see Moberg db_iam) given to the user upon login
 * - PARENT_STUDY_ID: The study the CONNECT Portal is currently in 
 * - STUDY_ID: The study associated with the patient file
 * - PATIENT_ID: The patient file itself
 * - DATA_OBJECT_ID: The patient file itself
 * 
 * To do so, run DataQuery.init with the given variables, for example:
 * 
 * ```DataQuery.init(userToken, parentStudy, studyId, patientFile);```
 */
export default class DataQuery {


    static init(USER_TOKEN, PARENT_STUDY_ID, STUDY_ID, PATIENT_ID, DATA_OBJECT_ID) {
        this.USER_TOKEN = USER_TOKEN
        this.PARENT_STUDY_ID = PARENT_STUDY_ID
        this.STUDY_ID = STUDY_ID
        this.PATIENT_ID = PATIENT_ID
        this.DATA_OBJECT_ID = DATA_OBJECT_ID
    }


    static USER_TOKEN = null
    static PARENT_STUDY_ID = null
    static STUDY_ID = null
    static PATIENT_ID = null
    static DATA_OBJECT_ID = null


    static ENDPOINT(ENDPOINT_INFO) {
        const { LINK, BODY_FUNCTION, JSON_RETURN = false } = ENDPOINT_INFO

        return async (...args) => {
            const body = BODY_FUNCTION(this.STUDY_ID, this.PATIENT_ID, this.DATA_OBJECT_ID, ...args)
            let response
            try {
                response = await fetch(
                    `${LINK}?parent_study_id=${this.PARENT_STUDY_ID}`,
                    {
                        method: "POST",
                        cache: "no-cache",
                        credentials: "same-origin", // TODO: Remove?
                        headers: {
                            "content-type": "application/json",
                            Authorization: this.USER_TOKEN,
                        },
                        body: JSON.stringify(body)
                    }
                )
            } catch (error) {
                console.error(`[🌐] Network error with endpoint: `, error)
                throw error
            }

            if (!response.ok) {
                const error_info = await response.text()
                console.error(`[🌐] Endpoint failed:`, error_info)
                throw new Error(error_info)
            }

            return JSON_RETURN ? await response.json() : response
        }
    }


    /**
     * @name ADD_ANNOTATION
     * 
     * @description Adds an annotation to the Moberg db_patient Database and to the actual annotation group inside the patient HDF5 file
     * @param {string} groupName
     * @param {annotation} annotation
     * @param {string} userEmail
     */
    static ADD_ANNOTATION = DataQuery.ENDPOINT(ENDPOINTS.ADD_ANNOTATION)


    /**
     * @name MODIFY_ANNOTATION
     * 
     * @description Modify an annotation in the Moberg db_patient Database and in the actual annotation group inside the patient HDF5 file
     * @param {string} groupName
     * @param {annotation} annotation
     */
    static MODIFY_ANNOTATION = DataQuery.ENDPOINT(ENDPOINTS.MODIFY_ANNOTATION)


    /**
     * @name GET_ANNOTATION_GROUPS
     * 
     * @description Loads all the annotation groups of the current patient HDF5 file
     */
    static GET_ANNOTATION_GROUPS = DataQuery.ENDPOINT(ENDPOINTS.GET_ANNOTATION_GROUPS)


    /**
     * @name CREATE_ANNOTATION_GROUP
     * 
     * @description Creates an annotation group/s in both the Moberg db_patient Database and the patient HDF5 file
     * @param {string} groupNames
     */
    static CREATE_ANNOTATION_GROUP = DataQuery.ENDPOINT(ENDPOINTS.CREATE_ANNOTATION_GROUP)


    /**
     * @name RENAME_ANNOTATION_GROUP
     * 
     * @description Rename an annotation group in both the Moberg db_patient Database and the patient HDF5 file
     * @param {string} groupNames
     * @param {string} newGroupName
     */
    static RENAME_ANNOTATION_GROUP = DataQuery.ENDPOINT(ENDPOINTS.RENAME_ANNOTATION_GROUP)


    /**
     * @name DELETE_ANNOTATION_GROUP
     * 
     * @description Deletes an annotation group/s from both the Moberg db_patient Database and the patient HDF5 file
     * @param {string} groupNames
     */
    static DELETE_ANNOTATION_GROUP = DataQuery.ENDPOINT(ENDPOINTS.DELETE_ANNOTATION_GROUP)


    /**
     * @name GET_ANNOTATIONS
     * 
     * @description Gets the annotations in the given annotation gruops
     * @param {string} groupNames
     */
    static GET_ANNOTATIONS = DataQuery.ENDPOINT(ENDPOINTS.GET_ANNOTATIONS)


    /**
     * @name DELETE_ANNOTATIONS
     * 
     * @description Deletes all annotations in an annotation group
     * @param {Array<number>} annotationIds
     */
    static DELETE_ANNOTATIONS = DataQuery.ENDPOINT(ENDPOINTS.DELETE_ANNOTATIONS)
}
