import { Columns, Column, Rows, Row } from "../../Utils/Styling";
import * as MdIcons from "react-icons/md";
import { Searchbar } from "../../../../Constants/StyledComponents";
import { useEffect, useState } from "react";
import { useCallback } from "react";
import { Annotation } from "../../Variables/Annotations";
import PrimaryCTAButton, { buttonColor } from "../../../../Components/PrimaryCTAButton/PrimaryCTAButton";
import { IntrusiveCheckboxButton, CheckboxButton } from "../../../../Components/CheckboxButton/CheckboxButton";
import { TableComponent, RowsContainer, Pagination } from "../../../../Components/TableComponent/TableComponent";
import { useModalProvider } from "../../../../Providers/ModalProvider";
import { annotationsAtom, selectedAnnotationAtom, selectedAnnotationGroupAtom } from "../../../../Pages/Data/Visualize/DataReview/Atoms/Annotations";
import { useRecoilValue, useSetRecoilState } from "recoil"
import { useAnnotationService } from "../../../../Hooks/useAnnotationService"
import { EditAnnotation } from "./EditAnnotation";
function getAnnotationDateString(annotation) {
    const _annotation = Annotation.fromConfig(annotation)
    const twoMinutes = 2 * 60 * 1e3
    const dateFormatOptions = { month: "short", day: "numeric", hour: 'numeric', minute: 'numeric', hour12: true }

    if (_annotation.isPointAnnotation() || annotation.end_time - annotation.start_time <= twoMinutes ) {
        return new Date(annotation.start_time).toLocaleDateString('en-us', dateFormatOptions)
    }

    const start = new Date(annotation.start_time).toLocaleDateString('en-us', dateFormatOptions)
    const end = new Date(annotation.end_time).toLocaleDateString('en-us', dateFormatOptions)

    return `${start} - ${end}`
}


export function AnnotationGroupAnnotationTable (props) {
    const { createModal } = useModalProvider()
    const { loadAnnotations, unloadAnnotations, getAnnotationsForGroups } = useAnnotationService()
    const selectedAnnotationGroup = useRecoilValue(selectedAnnotationGroupAtom)
    const renderedAnnotations = useRecoilValue(annotationsAtom)
    const setSelectedAnnotation = useSetRecoilState(selectedAnnotationAtom)

    const [annotations, setAnnotations] = useState()
    // annotation table data

    const [cachedGroups, setCachedGroups] = useState({}) // cached data for each annotation group

    const [rawAnnotationData, setRawAnnotationData] = useState([])
    const [annotationsTableColumns, setAnnotationsTableColumns] = useState()

    const [selectedAnnotations, setSelectedAnnotations] = useState([])

    const canLoad = selectedAnnotations.length > 0 && selectedAnnotations.some(annotation => !annotation.loaded)
    const canUnload = selectedAnnotations.length > 0 && selectedAnnotations.some(annotation => annotation.loaded)

    const withLoadedState = useCallback((annotation) => {
        const newAnnotation = new Annotation()
        Object.assign(newAnnotation, annotation)
        const loadedAnnotationIds = renderedAnnotations.map(annotation => annotation.id)
        newAnnotation.loaded = loadedAnnotationIds.includes(annotation.id)
        return newAnnotation
    }, [renderedAnnotations])

    useEffect(() => {
        props.handleSetSelectedAnnotations(selectedAnnotations)
    }, [selectedAnnotations, props])

    useEffect(() => {
        getAnnotationsForGroups([selectedAnnotationGroup.id]).then(annotations => {
            setRawAnnotationData(annotations.map(withLoadedState))
        }).finally(() => {
            setCachedGroups(prev => ({...prev, [selectedAnnotationGroup]: true}))
        })
    }, [getAnnotationsForGroups, selectedAnnotationGroup, withLoadedState])

    useEffect(() => {
        if (!selectedAnnotationGroup) {
            return
        }

        let columnsLabels = {
            preselected: {
                label: <IntrusiveCheckboxButton
                    state={rawAnnotationData.length === 0 ? false : rawAnnotationData.length === selectedAnnotations.length}
                    action={(nextState) => {
                        if (nextState) {
                            setSelectedAnnotations(rawAnnotationData)
                        } else {
                            setSelectedAnnotations([])
                        }
                    }}
                    checkboxSize={16}
                />,
                type: "checkbox",
                sortable: false,
                width: "47px",
                maxWidth: "47px",
                center: true
            },
            text: {label: "Title", type: "string", center: false, width: "180px", sortable: true},
            timestamp: {label: "Date", type: "string", center: false, width: "130px", sortable: true},
            type: {label: "Type", type: "string", center: false, width: "135px", sortable: true},
            loaded: {label: "Loaded", type: "boolean", center: true, width: "105px", sortable: true}
        }

        let columnData = []
		for (let elem of Object.keys(columnsLabels)) {
			columnData.push({
				name: columnsLabels[elem]['label'],
				selector: row => row[elem],
				sortable: columnsLabels[elem]['sortable'],
				center: columnsLabels[elem]['center'],
				width: columnsLabels[elem]['width'] ? columnsLabels[elem]['width'] : true,
				maxWidth: columnsLabels[elem]['maxWidth'] ? columnsLabels[elem]['maxWidth'] : "10000px",
				attr: elem
			})
		}

        let tableData = rawAnnotationData.map(row => {
            let entry = {
                'checkbox' : (<MdIcons.MdCheckBox size={16}/>)
            }

            // what in the world...
            for (let column of Object.keys(columnsLabels)) {
                if (columnsLabels[column]["type"] === "checkbox") {
                    let selected= selectedAnnotations.filter(annotation => {
                        return annotation.id === row.id
                    })
                    entry['preselected'] = 
                    <CheckboxButton
                        state={selected.length > 0}
                        action={(nextState) => onCheckAnnotation(row, nextState)}
                        checkboxSize={16}
                    />
                } else if (columnsLabels[column]["type"] === "string") {
                    entry['id'] = row['id']
                    if (column === "timestamp") {
                        if (row[column] === null) {
                            entry[column] = ""
                        } else {
                            entry[column] = getAnnotationDateString(row)
                        }
                    } else {
                        if (row[column] === null || row[column] === "") {
                            entry[column] = ""
                        } else {
                            entry[column] = `${row[column]}`
                        }
                    }
                } else if (columnsLabels[column]["type"] === "boolean") {
                    if (row[column]) {
                        entry[column] = (<MdIcons.MdCheck size={18} />)
                    } else {
                        entry[column] = ""
                    }
                } else {
                    entry[column] = row[column]
                }
            }
            return entry
        })

        setAnnotationsTableColumns(columnData)
        setAnnotations(tableData)

    }, [selectedAnnotationGroup, selectedAnnotations, renderedAnnotations, rawAnnotationData])

    function onCheckAnnotation(annotation, nextState) {
        if (nextState) {
            setSelectedAnnotations(prevState => [...prevState, annotation])
        } else {
            setSelectedAnnotations(prevState => prevState.filter(annotate => annotate.id !== annotation.id))
        }
    }

    const handleLoad = () => {
        setRawAnnotationData(prev => prev.map(withLoadedState))
        loadAnnotations(selectedAnnotations)
        setSelectedAnnotations([])
    }

    const handleUnload = () => {
       selectedAnnotations.map(selected => selected.id)
        setRawAnnotationData(prev => prev.map(annotation => {
            const loadedAnnotationIds = renderedAnnotations.map(annotation => annotation.id)
            const newAnnotation = new Annotation()
            Object.assign(newAnnotation, annotation)

            newAnnotation.loaded = loadedAnnotationIds.includes(annotation.id)

            return newAnnotation
        }))

        unloadAnnotations(selectedAnnotations)
        setSelectedAnnotations([])
    }

    const onEditModalSave = useCallback((data, selected, mode) => {
        setRawAnnotationData(prevAnnotations => 
            prevAnnotations.map(annotation => {
                const newAnnotation = new Annotation()
                Object.assign(newAnnotation, annotation)

                if (newAnnotation.id === selected[0].id) {
                    Object.assign(newAnnotation, data)
                }

                return newAnnotation
            })
        )
    }, [setRawAnnotationData])

    function openCustomAnnotationModal(selected, mode) {
        createModal(
            <EditAnnotation
                escClose={false}
                clickOutsideClose={false}
                onSave={data => { onEditModalSave(data, selected, mode) }}
            />
        )
    }

    function onSelectedRowsChange(e) {
        let selected = rawAnnotationData.filter(annotation => annotation.id === e.id)
        setSelectedAnnotation(selected[0])
        openCustomAnnotationModal(selected, "EDIT")
    }

    const [annotationSearchQuery, setAnnotationSearchQuery] = useState('')

    function checkAnnotationSearch(val) {
        if (annotationSearchQuery === "") {
			return val
		} else if (val.text.toLowerCase().includes(annotationSearchQuery.toLowerCase())) {
			return val
		} else if (String(val.timestamp).toLowerCase().includes(annotationSearchQuery.toLowerCase())) {
			return val
		} else if (String(val.type).toLowerCase().includes(annotationSearchQuery.toLowerCase())) {
			return val
		}
	}

    const permissions = { accessDataReview: true}
    const renderDataReview = () => null

    return (
    <Rows gap="5px">
        <Columns gap="5px">

            <h1 style={{margin: "5px 9px -5px 6px"}}>Annotations: </h1>
            <Column>
                <Searchbar className="ui left icon input" style={{width: "367px"}}>
					<input type="text" onChange={event => {setAnnotationSearchQuery(event.target.value)}} placeholder="Search"/> <i aria-hidden="true" class="search icon" />
				</Searchbar>
            </Column>

            <Columns gap="5px">
                <PrimaryCTAButton
                    buttonText="Load"
                    onClickFxn={handleLoad}
                    disabled={!canLoad}
                    color={buttonColor.blueFill}
                    buttonStyle={{height: "34px", padding: "0px 10px 0px 10px", marginRight: "3px"}}
                />

                <PrimaryCTAButton
                    buttonText="View data"
                    onClickFxn={renderDataReview}
                    disabled={!canUnload}
                    title={permissions.accessDataReview ? "" : "You do not have permission to Unload "}
                    color={buttonColor.redFill}
                    buttonStyle={{height: "34px", padding: "0px 10px 0px 10px", marginRight: "0px"}}
                />

			</Columns>
        
        </Columns>
        <Row>
            {selectedAnnotationGroup && cachedGroups[selectedAnnotationGroup]
                ? 
                <TableComponent
                    data={annotations.filter((val) => {
                        return checkAnnotationSearch(val)
                    })}
                    uniRef="text"
                >
                    <div style={{position: "relative"}}>
                        <RowsContainer
                            columns={annotationsTableColumns}
                            onRowClicked={(e) => onSelectedRowsChange(e)}
                        />
                        <Pagination/>
                    </div>
                </TableComponent>
            : <h2>Retrieving Session...</h2>}
        </Row>
    </Rows>
    )
}
