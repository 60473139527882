import { useCallback, useEffect, useState } from "react"
import { useSocketProvider } from "../../../../../Providers/SocketProvider"
import { useOnMount } from "../../../../../Hooks/useOnMount"
import { useEndpointProvider } from "../../../../../Providers/EndpointProvider"
import { useBackendLinksProvider } from "../../../../../Providers/BackendLinksProvider"
import { useWorkspacesProvider } from "../../../../../Providers/WorkspacesProvider"
import { useModalProvider } from "../../../../../Providers/ModalProvider"
import ConfirmationModal from "../../../../../Components/ConfirmationModal/ConfirmationModal"
import React from "react"
import { MobergTheme } from "../../../../../Moberg"
import UnloadFilesModal from "../../Subpages/UnloadFilesModal"
import { PrefetchInventory, FileToPreload, PrefetchQueue } from "../Types/Prefetch"
import { Deployment } from "../../../../../Providers/EnvironmentVariablesProvider"

type PrefetchSocketResponse = {
	prefetch_id: number
	prefetched_size: number
	finished: boolean
	waiting: number
	percentage: number
}

type usePrefetchDataProps = {
	prefetchId: number
	dataObjectId: number
	fileToPreload: FileToPreload
}

type usePrefetchDataAPI = {
	prefetchMessage: string
	prefetchIsFinished: boolean
	startPrefetch: () => void
	stopPrefetch: () => void
	skipPrefetch: () => void
	enoughSpace: boolean
}

export const usePrefetchData = ({ prefetchId, dataObjectId, fileToPreload }: usePrefetchDataProps): usePrefetchDataAPI => {
	const { createModal } = useModalProvider()
	const { selectedWorkspace } = useWorkspacesProvider()
	const { LINKS } = useBackendLinksProvider()
	const { post } = useEndpointProvider()
	const { getDataQuerySocket } = useSocketProvider()
	const prefetchSocket = getDataQuerySocket("prefetch")

	const isPrefetchDisabled = window.env.REACT_APP_DEPLOYMENT === Deployment.CLINICAL

	const [prefetchedSize, setPrefetchedSize] = useState<number>(0)
	const [prefetchMessage, setMessage] = useState<string>("Setting up files...")
	const [isRunning, setIsRunning] = useState<boolean>(false)
	const [prefetchIsFinished, setIsFinished] = useState<boolean>(isPrefetchDisabled)
	const [inventory, setInventory] = useState<PrefetchInventory | null>(null)
	const [queue, setQueue] = useState<PrefetchQueue | null>(null)
	const shouldCheckProgress = !prefetchIsFinished && isRunning && inventory !== null && queue !== null
	const [enoughSpace, setEnoughSpace] = useState<boolean>(true)

	useOnMount(() => {
		getPrefetchInventory().then(inventory => getPrefetchQueue(inventory))
	})

	const showErrorModal = (message: string) => {
		createModal(
			<ConfirmationModal
				title="There was a problem"
				description={message}
				confirmButton={{
					text: "OK",
					theme: MobergTheme.BLUE,
				}}
				showCancelButton={false}
			/>
		)
	}

	const getPrefetchInventory = (): Promise<any> => {
		return post(LINKS.ADMIN.PREFETCH.GET_PREFETCH_INVENTORY)
			.then(inventory => {
				if (inventory === null || inventory === undefined) {
					showErrorModal("There was a problem with the preload inventory. Please notify your admin that something went wrong.")
				} else {
					setInventory(inventory)
				}

				return inventory
			})
			.catch(error => showErrorModal(`We couldn't locate the files that are currently preloaded. Please notify your admin that something went wrong: ${error}`))
	}

	const getPrefetchQueue = (inventory: PrefetchInventory) => {
		const body = {
			study_id: selectedWorkspace,
		}

		// We can't do anything without the prefetch inventory.
		if (!inventory) {
			return
		}

		post(LINKS.ADMIN.PREFETCH.GET_PREFETCH_QUEUE, body)
			.then((prefetchQueue: PrefetchQueue) => {
				if (prefetchQueue === null || prefetchQueue === undefined) {
					showErrorModal("There was a problem with the preload queue. Please notify your admin that something went wrong.")
				} else {
					setQueue(prefetchQueue)
				}

				let queueSize = 0

				if (prefetchQueue.directories.length > 0) {
					queueSize = prefetchQueue.directories.reduce((accumulator, directory) => accumulator + directory.size, 0)
				}

				const availableSize = inventory.max_total_size - (inventory.current_size + queueSize)
				inventory.directories = inventory.directories.concat(prefetchQueue.directories)

				const dataObjectIsInInventory = inventory.directories.find(dir => Number(dir.data_object_id) === dataObjectId)
				const needsPreload = !dataObjectIsInInventory && fileToPreload?.preload_status.status === "0%"

				if (needsPreload && fileToPreload?.filesize > availableSize) {
					createModal(
						<UnloadFilesModal
							fileToPreload={fileToPreload}
							inventory={inventory}
							startPrefetch={startPrefetch}
							setEnoughSpace={setEnoughSpace}
						/>)
				} else {
					setEnoughSpace(true)
				}
			})
			.catch((error) => {
				showErrorModal(`We're not sure where you are in the preloading queue. Please notify your admin that something went wrong. ${error}`)
			})
	}

	const socketListener = useCallback(
		(socketResponse: PrefetchSocketResponse) => {
			if (socketResponse.prefetch_id !== prefetchId) {
				return
			}

			const { finished, percentage, waiting, prefetched_size: prefetchedSize } = socketResponse

			setPrefetchedSize(prefetchedSize)

			if (finished) {
				setIsFinished(true)
				setMessage("Files loaded")
				prefetchSocket.off("prefetch_data", socketListener)
				return
			}

			if (waiting > 0) {
				setMessage("Waiting to preload data... " + waiting + " patients ahead in queue")
			} else {
				setMessage("Preloading data " + percentage.toFixed(1) + "%")
			}
		},
		[prefetchId, prefetchSocket]
	)

	const startPrefetch = () => {
		if (isRunning) {
			return
		}

		setIsRunning(true)
		prefetchSocket.emit("prefetch_data", dataObjectId, prefetchId, prefetchedSize, false)
	}

	const stopPrefetch = () => {
		if (!isRunning) {
			return
		}

		prefetchSocket.off("prefetch_data")
		prefetchSocket.emit("prefetch_data", dataObjectId, prefetchId, prefetchedSize, true)
		setIsRunning(false)
		setPrefetchedSize(0)
	}

	// Skipping the prefetch step is useful for development cases where prefetching doesn't matter for Data Review
	const skipPrefetch = () => {
		stopPrefetch()
		setIsFinished(true)
	}

	// Every once in a while, ask the server what the status of the prefetch is.
	useEffect(() => {
		let checkTimeout: NodeJS.Timeout | undefined = undefined

		if (shouldCheckProgress) {
			checkTimeout = setInterval(() => {
				prefetchSocket.emit("prefetch_data", dataObjectId, prefetchId, prefetchedSize, false)
			}, 1000)
		}

		return () => {
			clearTimeout(checkTimeout)
		}
	}, [dataObjectId, prefetchId, prefetchSocket, prefetchedSize, shouldCheckProgress])

	// Hook on the socket listener and clean it up when necessary
	useEffect(() => {
		if (shouldCheckProgress) {
			prefetchSocket.on("prefetch_data", socketListener)
		}

		return () => {
			prefetchSocket.off("prefetch_data", socketListener)
		}
	}, [prefetchSocket, shouldCheckProgress, socketListener])

	return { prefetchMessage, prefetchIsFinished, startPrefetch, stopPrefetch, skipPrefetch, enoughSpace }
}
