import { range } from "lodash"
import { TimeSeriesData } from "../../../Data/TimeSeriesData"
import { bin } from "d3"

export const createHistogram = (data: Float32Array, binEdges: number[]): number[] => {
	const binGenerator = bin()
		.domain([binEdges[0], binEdges[binEdges.length - 1]])
		.thresholds(binEdges)

	return binGenerator(data).map(d => d.length)
}

export function binMeansUsingTimestampBins(timeSeriesData: TimeSeriesData, binEdges: number[], defaultValue: number | null = null): Array<number | null> {
	// Initialize an array to hold the sum of each bin's values and a count array to track the number of elements in each bin
	const binSums = new Array(binEdges.length - 1).fill(0)
	const binCounts = new Array(binEdges.length - 1).fill(0)

	// Find the bin index for each timestamp and update the sum & count of each bin.
	timeSeriesData.times.forEach((timestamp, index) => {
		if (timestamp === undefined || timestamp === null) {
			return
		}

		// Find the correct bin for the current timestamp
		for (let i = 0; i < binEdges.length - 1; i++) {
			if (timestamp >= binEdges[i] && timestamp < binEdges[i + 1]) {
				binSums[i] += timeSeriesData.data[index]
				binCounts[i]++
				break
			}
		}
	})

	// Calculate the mean for each bin
	return binSums.map((sum, index) => (binCounts[index] > 0 ? sum / Math.max(1, binCounts[index]) : defaultValue))
}

export const getBinEdges = (minimum: number, binSize: number, maximum: number) => {
	return range(minimum, maximum + binSize / 2, binSize)
}
