import React, { useState, useEffect } from 'react'
import { useEndpointProvider } from '../../../../Providers/EndpointProvider'
import { useModalProvider } from '../../../../Providers/ModalProvider'
import { useBackendLinksProvider } from "../../../../Providers/BackendLinksProvider"
import { humanReadableSize, ordinal_suffix_of } from '../../../../Computation/utilFunctions'
import { useGridApiRef } from '@mui/x-data-grid'
import MUITable from "../../../../Components/MUITable/MUITable"
import ModalHeader from "../../../../Components/ModalHeader/ModalHeader"
import styled from 'styled-components'

const UnloadFilesModal = ({ fileToPreload, inventory, startPrefetch, setEnoughSpace, unloadOnly = false }) => {
    const endpointProvider = useEndpointProvider()
    const { LINKS } = useBackendLinksProvider()
    const [selectedFiles, setSelectedFiles] = useState([])
    const [combinedInventory, setCombinedInventory] = useState(inventory)
    const [unloading, setUnloading] = useState()
    const { close } = useModalProvider()
    const apiRef = useGridApiRef()

    combinedInventory.current_size = combinedInventory.directories.reduce((accumulator, currentValue) => parseFloat(accumulator) + parseFloat(currentValue.size), 0)

    useEffect(() => {
        if (combinedInventory.directories?.length === 0) {
            setSelectedFiles([])
            return
        }
    }, [inventory, combinedInventory])

    async function unload(file_object_ids) {
        let body = {
            'data_object_ids': file_object_ids
        }
        return endpointProvider.post(LINKS.ADMIN.PREFETCH.UNLOAD, body)
    }

    async function getPrefetchQueue() {
        let body = {}
        return endpointProvider.post(LINKS.ADMIN.PREFETCH.GET_PREFETCH_QUEUE, body)
    }

    function unloadAndRefresh(data_object_ids) {
        const unloadAndFetch = async () => {
            let newInventory = await unload(data_object_ids)
            const queueData = await getPrefetchQueue()
            if ((newInventory.directories.length > 0) && queueData.directories.length > 0)
                newInventory.directories = newInventory.directories.concat(queueData.directories)
            let queue_size = queueData.directories.reduce((accumulator, currentValue) => parseFloat(accumulator) + parseFloat(currentValue.size), 0)
            newInventory.current_size += queue_size
            setCombinedInventory(newInventory)
            setUnloading(false)
        }
        unloadAndFetch()
    }

    function handleProceedPreload() {
        setEnoughSpace(true)
        startPrefetch()
        close()
    }

    function handleUnload() {
        setUnloading(true)
        let filesToUnload = combinedInventory.directories.filter((file) => selectedFiles.includes(file.name))
        let data_object_ids = filesToUnload.map(file => file.data_object_id)
        unloadAndRefresh(data_object_ids)

        setSelectedFiles([])
    }

    const maxTotalSize = humanReadableSize(parseFloat(combinedInventory.max_total_size))
    const currentSize = humanReadableSize(parseFloat(combinedInventory.current_size))
    const requestedSize = humanReadableSize(parseFloat(fileToPreload?.filesize))
    const needToUnloadSize = humanReadableSize(parseFloat(fileToPreload?.filesize) + parseFloat(combinedInventory.current_size) - parseFloat(combinedInventory.max_total_size))

    const unloadFilesToolbarProps = {
        createButton: { title: "Proceed with preload", isShow: !unloadOnly, onClick: handleProceedPreload, disabled: ((parseFloat(fileToPreload?.filesize) + parseFloat(combinedInventory.current_size) > parseFloat(combinedInventory.max_total_size)) || unloading) },
        deleteButton: { 
            title: "Unload", 
            isShow: true, 
            disabled: (selectedFiles.length === 0) || unloading, 
            onClick: handleUnload,
            onSuccessMessage: "Files unloaded successfully",
            confirmationDescription: "Are you sure you want to unload the selected files?",
            confirmationButtonText: "Yes, unload",
            isUnload: true
        },
        filterButton: { title: "Filter", isShow: false },
        exportButton: { isShow: false },
        searchInput: { isShow: false },
        columnButton: { isShow: false },
        nameForMultipleRows: "files"
    }

    const columns = [
        { field: 'site', headerName: 'Site', width: 240, sortable: false },
        { field: 'patient_id', headerName: 'Patient', width: 100, sortable: false },
        {
            field: 'name', headerName: 'File name', width: 300,
            valueGetter: (params) => {
                let displayedName = params.row.name.split("/")
                return displayedName[displayedName.length - 1]
            },
            sortable: false
        },
        {
            field: 'size', headerName: 'File size', width: 110,
            valueGetter: (params) => humanReadableSize(params.row.size),
            sortable: false
        },
        {
            field: 'status', headerName: 'Status', width: 160,
            valueGetter: (params) => {
                let status = "Not in use"
                if (params.row.in_queue_order !== undefined) {
                    status = params.row.in_queue_order === 0 ? `Loading ${params.row.percent_loaded.toFixed(2)}%` : `${ordinal_suffix_of(params.row.in_queue_order)} in queue`
                } else if (params.row.disabled) {
                    status = "In use"
                }
                return status
            },
            sortable: false
        },
        {
            field: 'data_object_id', headerName: 'Data object ID', width: 300, 'hide': true, sortable: false
        }
    ]

    const handleSelectionChange = (selectionModel) => {
        const selectedRows = selectionModel.map(id => apiRef.current.getRow(id))
        setSelectedFiles(selectedRows.map(row => row.name))
    }

    return (
        <Container>
            <ModalHeader headerText='Data ready for review' />

            <ContentWrapper>
                {unloadOnly ? (
                    <InfoText>
                        <strong>Info! </strong> The following files take up <strong>{currentSize}</strong> of space
                    </InfoText>
                ) : (
                    <>
                        {(fileToPreload?.filesize + combinedInventory.current_size > combinedInventory.max_total_size) && (
                            <InfoText>
                                <strong>Info! </strong> Only <strong>{maxTotalSize}</strong> of data can be loaded at once.
                                The following files take up <strong>{currentSize}</strong> of space, and you are requesting an additional <strong>{requestedSize}</strong>.
                                Please unload at least <strong>{needToUnloadSize}</strong> of space from inactive sessions to continue. If there are too many active sessions,
                                please coordinate with users in your workspace or contact Moberg Analytics Support for more space.
                            </InfoText>
                        )}

                        {(fileToPreload?.filesize + combinedInventory.current_size < combinedInventory.max_total_size) && (
                            <InfoText>
                                <strong>Info! </strong> There is now enough space to proceed with preload!
                            </InfoText>
                        )}
                    </>
                )}
            </ContentWrapper>

            <UnloadingMessage>
                {unloading &&
                    <p><strong>Unloading files.. </strong></p>
                }
            </UnloadingMessage>

            <TableWrapper>
                <MUITable
                    tableRef={apiRef}
                    columns={columns}
                    tableData={combinedInventory.directories}
                    tableToolbarProps={unloadFilesToolbarProps}
                    isCheckboxSelection={true}
                    onRowSelectionModelChange={handleSelectionChange}
                />
            </TableWrapper>
        </Container>
    )
}

export default UnloadFilesModal

const Container = styled.div`
    background: #FFFFFF;
    width: 1050px;
    height: 776px;
    display: flex;
    padding-top: 20px;
    padding-bottom: 188px;
    overflow-x: hidden;
    flex-direction: column;
`

const ContentWrapper = styled.div`
    height: auto;
    margin-top: 20px;
    padding-left: 50px;
    padding-right: 50px;
`

const InfoText = styled.p`
    strong {
        font-weight: bold;
    }
`

const UnloadingMessage = styled.div`
    margin-top: 20px;
    display: flex;
    align-self: center;
`

const TableWrapper = styled.div`
    height: 380px;
    margin-top: 20px;
    padding-left: 50px;
    padding-right: 50px;
    overflow-y: auto;
`