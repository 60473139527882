export type EEGMontages = {
	"Longitudinal-bipolar": string
	"Bipolar-Transverse": string
	"SD-Detection": string
	Recorded: string
	"Create Montage": string
}

export const EEG_LOW_FILTERS = { "0.1Hz": 0.1, "0.5Hz": 0.5, "1Hz": 1.0, "2Hz": 2.0, "3Hz": 3.0, Off: 0 }

export const EEG_HIGH_FILTERS = { Off: 0, "100Hz": 100.0, "90Hz": 90.0, "80Hz": 80.0, "70Hz": 70.0, "60Hz": 60.0, "50Hz": 50.0, "40Hz": 40.0, "30Hz": 30.0 }

export const EEG_NOTCH_FILTERS = { "60Hz": 60.0, "50Hz": 50.0, Off: 0 }

export const EEG_SENSITIVITY = {
	"1000 uV": 1000 * 1e-6,
	"700 uV": 700  * 1e-6,
	"500 uV": 500 * 1e-6,
	"300 uV": 300 * 1e-6,
	"200 uV": 200 * 1e-6,
	"150 uV": 150 * 1e-6,
	"100 uV": 100 * 1e-6,
	"70 uV": 70 * 1e-6,
	"50 uV": 50 * 1e-6,
	"30 uV": 30 * 1e-6,
	"20 uV": 20 * 1e-6,
	"15 uV": 15 * 1e-6,
	"10 uV": 10 * 1e-6,
	"7 uV": 7 * 1e-6,
	"5 uV": 5 * 1e-6,
	"3 uV": 3 * 1e-6,
	"2 uV": 2 * 1e-6,
	"1 uV": 1 * 1e-6,
}
export class EEGDisplayConfig {
	public LFF: number
	public HFF: number
	public notch: number
	public sensitivityMicroVolts: number

	private montage: EEGMontage | null
	private montageMapping: Map<string, string> // from the physical inputs on the amp, to the lead names


	constructor(montage: EEGMontage | null, LFF = 1.0, HFF = 30.0, notch = 60.0, sensitivityMicroVolts = 1.0, montageMapping=new Map()) {
		this.montage = montage
		this.LFF = LFF
		this.HFF = HFF
		this.notch = notch
		this.sensitivityMicroVolts = sensitivityMicroVolts
		this.montageMapping = montageMapping
	}

	// For display purposes, replace the channel pair with an optional alias.
	get channelLabels(): string[] {
        return this.validChannels().map(({ first, second, alias }) => {
			if (alias) {
				return alias
			}
			return `${this.montageMapping.get(first)}-${this.montageMapping.get(second)}`
		})
	}

	// The strings that uniquely identify each channel from a data perspective.
	get channelDataKeys(): string[] {
        return this.validChannels().map(({ first, second }) => `${this.montageMapping.get(first)}-${this.montageMapping.get(second)}`) ?? []
	}

	get leads(): string[] {
		const leads = this.validChannels()
			.flatMap(({ first, second }) => [first, second])
			.map((lead: string) => `EEG_${this.montageMapping.get(lead)}`)
			.filter((lead: string) => lead !== "EEG_Ref")

		return [...new Set(leads)]
	}

	get channelPairs(): string[][] {
		return this.validChannels().map(({ first, second }) => (
			[
				this.montageMapping.get(first) as string,
				this.montageMapping.get(second) as string
			]
		))
	}

	get eegModality(): string | undefined {
		if (this.channelLabels.length > 0) {
			const channels = this.validChannels().map(({ first, second }) => `${this.montageMapping.get(first)}-${this.montageMapping.get(second)}`)
			return `Montage=${channels.join(",")}:${this.qualifiers}`
		}

		return undefined
	}

	get qualifiers() {
		return "Notch=" + this.notch + "+HFF=" + this.HFF + "+LFF=" + this.LFF
	}

	private validChannels = () => {
		const montageChannels = this.montage?.channels ?? []
		return montageChannels.filter(({ first, second }) => this.montageMapping.has(first) && this.montageMapping.has(second))
	}
}

export type EEGChannel = {
	first: string
	second: string
	external: boolean
	alias?: string
}

export type EEGMontage = {
	id: number
	name: string
	type: MontageType
	userId: number | null
	channels: EEGChannel[]
}

export enum MontageType {
	PERSONAL = "personal", // Only visible to the user who owns it. Fully editable.
	SHARED = "shared", // Visible to all users in the workspace. Editable with appropriate permissions.
	STANDARD = "standard" // Visible to all users. Non-editable.
}
