import { ModalityDataSource } from "../Types/ModalityDataSource"
import { PageType } from "../Types/PageType"
import { PersystSocketResponse } from "../Types/SocketResponse"
import { ModalityPageProperties, Page } from "./Page"

export type ImagePageProperties = ModalityPageProperties & {
	height: number
}

export class ImagePage<Properties extends ImagePageProperties=ImagePageProperties> extends Page<ArrayBuffer, Properties> {
	readonly height: number = 0

	private _loading = false

	get loaded(): boolean {
		return this.modalityDataSources.every(dataSource => (
			this.data.get(dataSource.dataObjectId)?.get("image") !== undefined
		))
	}

	get loading(): boolean {
		return this._loading
	}

	public getType(): PageType {
		return PageType.IMAGE
	}

	public load = () => {
		return new Promise<Page<ArrayBuffer>>((resolve, reject) => {
			if (this.loaded || this.loading || this.width === 0) {
				resolve(this)
				return
			}

			this._loading = true

			if (this.getDataQuerySocket === null) {
				return
			}

			const socket = this.getDataQuerySocket(this.socketId)

			const listener = (socketResponse: PersystSocketResponse) => {
				if (socketResponse.page_id === this.id) {
					const dataObjectData = this.data.get(socketResponse.data_object_id)

					// Set default
					if (!dataObjectData) {
						this.data.set(socketResponse.data_object_id, new Map())
					}

					this.data.get(socketResponse.data_object_id)?.set("image", socketResponse.image)
					socket.off(this.socketEventName(), listener)
					this._loading = false
					resolve(this)
				}
			}

			socket.on(this.socketEventName(), listener)
			socket.on("disconnect", reject)

			// Creating a batch for all of the modalities
			const batch = new Map<number, ModalityDataSource[]>()

			this.modalityDataSources.forEach(dataSource => {
				const current = batch.get(dataSource.dataObjectId) ?? []
				batch.set(dataSource.dataObjectId, [...current, dataSource])
			})

			this.requestData(batch, socket)

			setTimeout(() => reject("socket timed out"), 60_000)
		})
	}
}
