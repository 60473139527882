
import { Empty } from 'antd';
import React, { useEffect, useState, createContext } from 'react';
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import { ModalFooter, Scrollbar } from "../../../../Constants/StyledComponents";
import { useLayoutService } from "../../../../Hooks/useLayoutService";
import { editedLayoutGroupAtom, madeChangesToEditedLayoutGroupAtom, uneditedLayoutGroupAtom } from "../../../../Pages/Data/Visualize/DataReview/Atoms/Layout";
import { useModalProvider } from "../../../../Providers/ModalProvider";
import { useModalitiesProvider } from "../../../../Providers/ModalitiesProvider";
import { modalityGraphGroupConfigAtom } from '../../../../Pages/Data/Visualize/DataReview/Atoms/ModalityGraphGroup';
import { MobergButton, MobergButtonShape, MobergButtonVariant, MobergFontSize, MobergTheme, MobergHeader, MobergShortTextInput, MobergDropdown, MobergColumn, MobergRow, MobergText, getColors, MobergNumberInput, MobergInputLabel, MobergIconSize } from '../../../../Moberg';
import ConfirmationModal from '../../../../Components/ConfirmationModal/ConfirmationModal';
import { DataSource } from '../../../../Pages/Data/Visualize/DataReview/Types/DataSource'
import { GraphToggleButton } from "./GraphToggleButton"
import { RenderStrategy } from '../../../../Pages/Data/Visualize/DataReview/Types/Trace';
import { LineGraphModalities } from "./LineGraphModalities/LineGraphModalities"
import { HeatmapGraphModalities } from "./HeatmapGraphModalities/HeatmapGraphModalities"
import { ColorSpectrum } from '../../../../Pages/Data/Visualize/DataReview/Types/ColorSpectrum';
import { ConfigureWindowModalHeader } from "./ConfigureWindowModalHeader"
import { currentPatientFileInfoAtom } from '../../../../Pages/Data/Visualize/DataReview/Atoms/PatientFile';
import { isGenericAnalytic } from '../../../../Pages/Data/Visualize/DataReview/Types/AnalysisDetails';
import { analyticTracesAtom } from "../../../../Pages/Data/Visualize/DataReview/Atoms/Analytics"
import { MdCheckBox, MdCheckBoxOutlineBlank } from 'react-icons/md';

export const ConfigureWindowModalContext = createContext({})

export function ConfigureWindowModal({ layoutId, windowId }) {
    const { createModal, close } = useModalProvider()
    const { modifyLayoutGroup } = useLayoutService()
    const setUneditedLayoutGroup = useSetRecoilState(uneditedLayoutGroupAtom)
    const setTimeSeriesGraphGroup = useSetRecoilState(modalityGraphGroupConfigAtom({ windowId, layoutId }))
    const [{ patientModalities }, ] = useRecoilState(currentPatientFileInfoAtom)
    const analyticTraceOptions = useRecoilValue(analyticTracesAtom)

    const modalitiesProvider = useModalitiesProvider()
    const madeChanges = useRecoilValue(madeChangesToEditedLayoutGroupAtom)

    const [editedLayoutGroup, setEditedLayoutGroup] = useRecoilState(editedLayoutGroupAtom)
    const [selectedGraphId, setSelectedGraphId] = useState()
    const [waitingForNetwork, setWaitingForNetwork] = useState(false)
    const [error, setError] = useState() // network error. useAsyncTask handles this better than defining the state here.
    const [validationErrors, setValidationErrors] = useState(new Map())
    const validationErrorsArray = Array.from(validationErrors.values())
    const red = getColors(MobergTheme.RED).main

    const currentLayout = editedLayoutGroup?.layouts.find(layout => layout.id === layoutId)
    const currentWindow = currentLayout?.areas.find(({ area }) => area === windowId)
    const currentGraph = !selectedGraphId && (currentWindow?.props?.graphs ?? []).length > 0
        ? currentWindow.props.graphs[0]
        : currentWindow?.props.graphs.find(graph => selectedGraphId && graph.id === selectedGraphId)

    const sortedModalities = Object.keys(modalitiesProvider.modalities).sort();

    const traceOptions = sortedModalities.filter(modality => !modality.startsWith('EEG')).map(name => ({
        label: name,
        value: {
            name,
            dataKey: name,
            rawDataKey: name,
            dataSource: DataSource.CURRENT_PATIENT,
        },
        highlight: patientModalities.includes(name)
    }))

    traceOptions.push(...analyticTraceOptions.map(traceDataConfig => ({
        label: traceDataConfig.name,
        value: traceDataConfig,
        highlight: false
    })))

    useEffect(() => {
        if (!selectedGraphId && currentWindow?.props.graphs.length > 0) {
            setSelectedGraphId(currentWindow.props.graphs[0].id)
        }

    }, [currentWindow.props.graphs, selectedGraphId])

    const toggleCurrentGraph = (graph) => {
        if (selectedGraphId === graph.id) {
            return
        }

        setSelectedGraphId(graph.id)
    }

    function addGraph() {

        const newGraph = {
            id: `graph-${new Date(Date.now()).toISOString()}`,
            name: 'New graph',
            traces: [],
            renderStrategy: RenderStrategy.LINE,
            min: 0,
            max: 100,
            defaultMin: 0,
            defaultMax: 100
        }

        // This is so complex, it probably should be being done by a reducer.
        setEditedLayoutGroup(previous => {
            return {
                ...previous,
                layouts: previous.layouts.map(layout => {
                    if (layout.id === layoutId) {
                        return {
                            ...layout,
                            areas: layout.areas.map(area => {
                                if (area.area === windowId) {
                                    return {
                                        ...area,
                                        props: {
                                            ...area.props,
                                            graphs: [...area.props.graphs ?? [], newGraph]
                                        }
                                    }
                                }
                                return area
                            })
                        }
                    }
                    return layout
                })
            }
        })
    }

    function removeGraph(graph) {
        // This is so complex, it probably should be being done by a reducer.
        setEditedLayoutGroup(previous => {
            return {
                ...previous,
                layouts: previous.layouts.map(layout => {
                    if (layout.id === layoutId) {
                        return {
                            ...layout,
                            areas: layout.areas.map(area => {
                                if (area.area === windowId) {
                                    return {
                                        ...area,
                                        props: {
                                            ...area.props,
                                            graphs: [...area.props.graphs?.filter(g => g !== graph)] ?? []
                                        }
                                    }
                                }
                                return area
                            })
                        }
                    }
                    return layout
                })
            }
        })
    }

    const modalBodyGraph = (
        <MobergColumn style={{ padding: "16px" }}>
            <MobergColumn gap="8px">
                <MobergHeader>
                    Graphs
                </MobergHeader>

                <MobergColumn gap="8px">
                    <div>
                        {currentWindow?.props.graphs.length > 0
                            ? currentWindow.props.graphs.map(graph => (
                                <GraphToggleButton
                                    graph={graph}
                                    isActive={graph.id === currentGraph?.id}
                                    removeGraph={removeGraph}
                                    toggleCurrentGraph={toggleCurrentGraph}
                                />))
                            : <Empty description={<p style={{ fontFamily: 'Source Sans Pro', fontSize: '26px' }}>No Graphs</p>} />
                        }
                    </div>

                    <MobergButton
                        onClick={addGraph}
                        shape={MobergButtonShape.FULL_WIDTH}
                        theme={MobergTheme.BLUE}
                        variant={MobergButtonVariant.OUTLINE}
                    >
                        Add graph
                    </MobergButton>

                </MobergColumn>
            </MobergColumn>
        </MobergColumn>
    )

    function saveChanges() {
        setWaitingForNetwork(true)
        modifyLayoutGroup(editedLayoutGroup.id, editedLayoutGroup)
            .then(close)
            .catch(err => {
                setWaitingForNetwork(false)
                setError(err)
            })
        setUneditedLayoutGroup(editedLayoutGroup)
        setTimeSeriesGraphGroup(previous => ({ ...previous, ...currentWindow.props }))
    }

    function closeConfigurationModal() {
        if (madeChanges) {
            createModal(
                <ConfirmationModal
                    title={"Discard Changes?"}
                    description={"You have unsaved changes. Are you sure you want to close without saving?"}
                    confirmButton={{ text: "Close without saving", theme: MobergTheme.RED, onClick: close }}
                />
            )
        } else {
            close()
        }
    }

    function updateGraphProperty(property, valueOrSetter) {
        setEditedLayoutGroup(previous => {
            const newLayoutGroup = {
                ...previous,
                layouts: previous.layouts.map(layout => {
                    if (layout.id === layoutId) {
                        return {
                            ...layout,
                            areas: layout.areas.map(window => {
                                if (window.area === windowId) {
                                    return {
                                        ...window,
                                        props: {
                                            ...window.props,
                                            graphs: window.props.graphs.map(graph => {
                                                if (graph.id === currentGraph?.id) {
                                                    let newValue

                                                    if (valueOrSetter instanceof Function) {
                                                        newValue = valueOrSetter(graph[property])
                                                    } else {
                                                        newValue = valueOrSetter
                                                    }

                                                    return {
                                                        ...graph,
                                                        [property]: newValue
                                                    }
                                                }
                                                return graph
                                            })
                                        }
                                    }
                                }
                                return window
                            })
                        }
                    }
                    return layout
                })
            }

            return newLayoutGroup
        })
    }

    function updateDisplayPageSize(newValue) {
        setEditedLayoutGroup(previous => {
            const newLayoutGroup = {
                ...previous,
                layouts: previous.layouts.map(layout => {
                    if (layout.id === layoutId) {
                        return {
                            ...layout,
                            areas: layout.areas.map(window => {
                                if (window.area === windowId) {
                                    return {
                                        ...window,
                                        props: {
                                            ...window.props,
                                            viewDuration: newValue
                                        }
                                    }
                                }
                                return window
                            })
                        }
                    }
                    return layout
                })
            }

            return newLayoutGroup
        })
    }

    function removeTrace(id) {
        const newTraces = [...(currentGraph?.traces ?? [])]
            .filter(trace => trace.id !== id)
            .filter(trace => trace.renderStrategy === RenderStrategy.DELTA ? trace.first?.id !== id && trace.second?.id !== id : true)

        updateGraphProperty("traces", newTraces)
    }

    const handleGraphTypeChanged = (renderStrategy) => {
        updateGraphProperty("renderStrategy", renderStrategy)

        let defaultProps

        switch (renderStrategy) {
            case RenderStrategy.LINE:
                defaultProps = { color: "#000" }
                break

            case RenderStrategy.HEATMAP:
                defaultProps = {
                    colorSpectrum: ColorSpectrum.INFERNO,
                    lowerBound: 0,
                    upperBound: 100
                }
                break
            default:
                throw new Error("unexpected graph type: " + renderStrategy)
        }

        updateGraphProperty("traces", previous => previous
            .filter(trace => trace.renderStrategy !== RenderStrategy.DELTA)
            .map(trace => ({ ...trace, ...defaultProps, renderStrategy }))
        )
        setValidationErrors(new Map())
    }

    const getModalitiesSection = () => {
        switch (currentGraph?.renderStrategy) {
            case RenderStrategy.HEATMAP:
                return <HeatmapGraphModalities />
            case RenderStrategy.LINE:
            default:
                return <LineGraphModalities />
        }
    }

    const currentTraces = currentGraph?.traces ?? []

    const activeSourceTraces = currentTraces.filter(trace => 
        trace.renderStrategy !== RenderStrategy.DELTA &&
        (!trace.onDemandAnalysis || isGenericAnalytic(trace.onDemandAnalysis?.analytic))
    )

	const activeAnalyticTraces = currentTraces.filter(trace => trace.onDemandAnalysis?.analytic && !isGenericAnalytic(trace.onDemandAnalysis?.analytic))
	const activeDeltaRegions = currentTraces.filter(trace => trace.renderStrategy === RenderStrategy.DELTA)

    return (
        <ConfigureWindowModalContext.Provider value={{ traceOptions, currentGraph, currentWindow, activeSourceTraces, activeAnalyticTraces, activeDeltaRegions, closeModal: closeConfigurationModal, updateDisplayPageSize, updateGraphProperty, removeTrace, setValidationErrors }}>
            <MobergColumn style={{ width: "90vw", height: "90vh" }}>

                <ConfigureWindowModalHeader />

			    <hr style={{ height: '0px', border: '1px solid #B3B3B3', margin: 0 }} />

                <MobergRow style={{ flex: 1, height: "100%", minHeight: 0, overflow: "hidden" }} verticalAlign='start'>
                    <MobergColumn style={{ height: "100%", minWidth: 0, overflow: "auto" }}>
                        <Scrollbar>
                            {modalBodyGraph}
                        </Scrollbar>
                    </MobergColumn>


                    <MobergColumn style={{ flex: 1, height: "100%", minHeight: 0, minWidth: 0, overflow: "auto" }}>
                        <Scrollbar style={{ flex: 1, display: "flex", borderLeft: '2px solid #DDDDDD' }}>
                        <MobergRow style={{ flex: 1, height: "100%" }} expand={false} horizontalAlign={currentGraph ? "start" : "center"}>
                            {!currentGraph
                                ? (<MobergColumn verticalAlign="center" expand={true}>
                                    <MobergRow horizontalAlign="center" expand={true}>
                                        <MobergText fontSize={MobergFontSize.LARGE} style={{ padding: "16px" }}>
                                            Select a graph to customize
                                        </MobergText>
                                    </MobergRow>
                                </MobergColumn>
                                )
                                : (
                                    <MobergColumn style={{ flex: 1, height: "100%", background: '#FFFFFF'}}>
                                            <MobergColumn gap="48px" style={{ padding: "16px", flex: 1 }}>
                                                <MobergColumn gap="16px">
                                                    <MobergHeader>
                                                        Graph properties
                                                    </MobergHeader>

                                                    <MobergRow gap="8px">
                                                        <MobergColumn gap="8px" style={{ padding: "0px 16px" }}>
                                                            <MobergRow gap="8px">
                                                                <MobergInputLabel text="Title" style={{ width: "45px" }} />
                                                                <div>
                                                                    <MobergShortTextInput
                                                                        orientation="row"
                                                                        limit={25}
                                                                        defaultValue={currentGraph?.name}
                                                                        value={currentGraph?.name}
                                                                        onChange={newName => updateGraphProperty("name", newName)}
                                                                    />
                                                                </div>
                                                            </MobergRow>

                                                            <MobergRow gap="8px">
                                                                <MobergInputLabel text="Type" style={{ width: "45px" }} />
                                                                <MobergDropdown
                                                                    selectedValue={currentGraph?.renderStrategy}
                                                                    options={[
                                                                        { label: "Line", value: RenderStrategy.LINE },
                                                                        { label: "Heatmap", value: RenderStrategy.HEATMAP }
                                                                    ]}
                                                                    onChange={handleGraphTypeChanged}
                                                                />
                                                            </MobergRow>
                                                           
                                                        </MobergColumn>
                                                        
                                                        {currentGraph?.renderStrategy !== RenderStrategy.HEATMAP && (<>
                                                            <MobergColumn gap="8px" style={{ padding: "0px 16px" }}>
                                                                <MobergRow gap="8px">
                                                                    <MobergInputLabel text="Y axis min" style={{ width: "80px" }} />
                                                                    <MobergNumberInput
                                                                        key={`y-axis-min-${currentGraph?.id}`}
                                                                        defaultValue={currentGraph?.defaultMin ?? 0}
                                                                        value={currentGraph?.defaultMin ?? 0}
                                                                        onChange={newValue => updateGraphProperty("defaultMin", newValue)}
                                                                    />
                                                                </MobergRow>

                                                                <MobergRow gap="8px">
                                                                    <MobergInputLabel text="Y axis max" style={{ width: "80px" }} />
                                                                    <MobergNumberInput
                                                                        key={`y-axis-max-${currentGraph?.id}`}
                                                                        defaultValue={currentGraph?.defaultMax ?? 100}
                                                                        value={currentGraph?.defaultMax ?? 100}
                                                                        onChange={newValue => updateGraphProperty("defaultMax", newValue)}
                                                                    />
                                                                </MobergRow>
                                                                
                                                            </MobergColumn>

                                                            <MobergRow gap="8px">
                                                                <MobergInputLabel text={"Auto-scale"} />

                                                                <MobergButton 
                                                                    shape={MobergButtonShape.SQUARE}
                                                                    theme={currentGraph?.autoScale ? MobergTheme.BLUE : MobergTheme.BLACK}
                                                                    onClick={() => updateGraphProperty("autoScale", !currentGraph?.autoScale)}
                                                                >
                                                                    {currentGraph?.autoScale 
                                                                        ? <MdCheckBox size={MobergIconSize.REGULAR} />
                                                                        : <MdCheckBoxOutlineBlank size={MobergIconSize.REGULAR} />
                                                                    }

                                                                </MobergButton>
                                                            </MobergRow>
                                                        </>
                                                        )}
                                                    </MobergRow>
                                                </MobergColumn>
                                                
                                                {getModalitiesSection()}
                                                
                                            </MobergColumn>
                                    </MobergColumn>
                                )
                            }
                        </MobergRow>
                        </Scrollbar>
                    </MobergColumn>
                </MobergRow>

                <hr style={{ height: '0px', border: '1px solid #B3B3B3', margin: 0 }} />
                <ModalFooter>
                    <MobergRow gap="16px" horizontalAlign="right">
                        {waitingForNetwork && (
                            <div style={{ display: "flex", alignItems: "center" }}> Please wait... </div>
                        )}

                        {!waitingForNetwork && error && (
                            <div style={{ display: "flex", alignItems: "center", color: red }}> {error} </div>
                        )}

                        {validationErrorsArray.length > 0 && (
                            <MobergText style={{ color: red }}>
                                {validationErrorsArray[0]}
                            </MobergText>
                        )}

                        <MobergButton
                            disabled={!madeChanges || waitingForNetwork || Array.from(validationErrors).length > 0}
                            onClick={saveChanges}
                            theme={MobergTheme.BLUE}
                            variant={MobergButtonVariant.FILLED}
                            fontSize={MobergFontSize.LARGE}
                            shape={MobergButtonShape.WIDE}
                        >
                            Save
                        </MobergButton>
                    </MobergRow>
                </ModalFooter>
            </MobergColumn>
        </ConfigureWindowModalContext.Provider>
    )
}

export default ConfigureWindowModal;
