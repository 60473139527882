import React, { useState, useEffect } from 'react';
import styled from "styled-components";
import * as MdIcons from "react-icons/md";
import { useMode, VIZ_MODES } from '../VisualizationManager';
import { TabSelector } from '../../../Components/Tabs/TabSelector';
import { Column } from '../Utils/Styling';
import { invertedObject } from '../Utils/Convenience';
import { ControlledTabs } from '../../../Components/Tabs/Tabs';
import { AnnotationTable } from "../../../Components/DataTable/Table";
import { useRecoilState, useSetRecoilState } from 'recoil';
import { hotkeysEnabledAtom } from '../../../Pages/Data/Visualize/DataReview/Atoms/Hotkeys';
import { annotationGroupsAtom, annotationsAtom, activeAnnotationSessionAtom } from '../../../Pages/Data/Visualize/DataReview/Atoms/Annotations';
import { useRecoilValue } from "recoil"
import { useModalProvider } from '../../../Providers/ModalProvider';
import { AnnotationSessionManagerModal } from '../ToolBar/Modals/AnnotationSessionManagerModal';
import { jumpAllWindows } from '../../../Pages/Data/Visualize/DataReview/Atoms/Visualizations';

import { MobergAnimationTiming, MobergAnimationCurve, MobergDropdown, MobergRow, MobergButtonShape, MobergIconSize, MobergDropdownSize, MobergButton } from '../../../Moberg';

function ActionsBar(props) {
    const { createModal } = useModalProvider()
    const annotations = useRecoilValue(annotationsAtom)
    const setHotkeysEnabled = useSetRecoilState(hotkeysEnabledAtom)

    const modeToTab = {
        [VIZ_MODES.ANNOTATE]: "Annotate",
    }
    const tabToMode = invertedObject(modeToTab)
    const toolbarTabs = Object.values(modeToTab)

    const [mode, setMode] = useMode()  
    const selectedTab = modeToTab[mode]  
    const setSelectedTab = (tab) => setMode(tabToMode[tab])

    const annotationGroups = useRecoilValue(annotationGroupsAtom)
	const [activeAnnotationSession, setActiveAnnotationSession] = useRecoilState(activeAnnotationSessionAtom)    

    const monitoringData = [...Array(24).fill(0).map(x => ({annotation: '', start_time: '', end_time: '', duration: ''}))]

    const dropdownOptions = annotationGroups.map(group => ({
        label: group.group_name,
        value: group.id
      }));

    const handleDropdownChange = (value) => {
        const selectedGroup = annotationGroups.find(group => group.id === value);
        setActiveAnnotationSession(selectedGroup);
      };
            
    const [monitoringTableColumns, setMonitoringTableColumns] = useState([])
    const [monitoringTableData, setMonitoringTableData] = useState([])

    function msToTime(ms) {
        let seconds = (ms / 1000).toFixed(1);
        let minutes = (ms / (1000 * 60)).toFixed(1);
        let hours = (ms / (1000 * 60 * 60)).toFixed(1);
        let days = (ms / (1000 * 60 * 60 * 24)).toFixed(1);
        if (seconds < 60) return seconds + " Sec";
        else if (minutes < 60) return minutes + " Min";
        else if (hours < 24) return hours + " Hrs";
        else return days + " Days"
    }

    useEffect(() => {
        let columns2labels = {
            annotation: { label: "Annotation", type: "string" },
            start_time: { label: "Start Time", type: "string" },
            end_time: { label: "End Time", type: "string" },
            duration: { label: "Duration", type: "string" },
        }

        let columnData = []

        for (let elem of Object.keys(columns2labels)) {
            columnData.push({
                name: columns2labels[elem]['label'],
                selector: elem,
            })
        }

        setMonitoringTableColumns(columnData)
        let annotationTableData = monitoringData
        let annotationsIds = annotations.map(annotation => annotation.id)

        const dateFormatOptions = {month: "short", day: "numeric", hour: 'numeric', minute: 'numeric', hour12: true };

        if (annotationsIds.length > 0) {
            if (!(annotationsIds.length >= annotationTableData.length)) {
                annotationTableData.forEach(row => {
                    const annotation = annotations.find(annotation => annotation.id === annotationsIds[annotationTableData.indexOf(row)])

                    if (annotation && row.annotation === "") {
                        const start = new Date(annotation.start_time)
                        const end = new Date(annotation.end_time)

                        row.annotation = annotation.text
                        row.start_time = start.toLocaleDateString("en-us", dateFormatOptions)
                        row.end_time =  end.toLocaleDateString("en-us", dateFormatOptions)
                        row.duration = msToTime(end - start)
                        row.annotation_id = annotation.id
                    }
                })
            } else {
                annotationTableData = annotations.map(annotation => (
                    {
                        annotation: annotation.text,
                        start_time: new Date(annotation.start_time).toLocaleDateString("en-us", dateFormatOptions),
                        end_time: new Date(annotation.end_time).toLocaleDateString("en-us", dateFormatOptions),
                        duration: msToTime(annotation.end_time - annotation.start_time),
                        annotation_id: annotation.id
                    }
                ))
            }
        } else {
            setMonitoringTableData(monitoringData)
        }
        
        
        setMonitoringTableData(annotationTableData)
    }, [annotations])

    const [searchQuery, setSearchQuery] = useState('');


    useEffect(() => {
        if (!props.enableSidebar) {
            document.getElementById('Sidebar').style.transform = 'translate3d(100%,0,0)'
            document.getElementById('Sidebar').style.width = '0px'
            document.getElementById('SideBarContent').style.display = 'none'
        } else {
            document.getElementById('Sidebar').style.transform = 'translate3d(0, 0, 0)'
            document.getElementById('Sidebar').style.width = props.sidebarWidth ? props.sidebarWidth : '30%'
            document.getElementById('SideBarContent').style.display = 'block'
        }
    }, [props.enableSidebar, props.sidebarWidth])

    const onRowClick = row => {
        const annotation = annotations.find(annotation => annotation.id === row.annotation_id)

        if (annotation) {
            jumpAllWindows(new Date(annotation.start_time))
        }
    }

    function checkUploadsSearch(val) {
		if (searchQuery === "") {
            return val;
        } else if (
            val.annotation
                .toLowerCase()
                .includes(searchQuery.toLowerCase())
        ) {
            return val;
        }   
	}

    function filterUserTableData(data) {
        let filteredPatientTableData = data.filter(row => {
                return checkUploadsSearch(row)
            }
        )

        return filteredPatientTableData
    }

    const enableHotKeys = () => {setHotkeysEnabled(true); }
    const disableHotKeys = () => {setHotkeysEnabled(false); }

    function renderAnnotationSessionModal () {
        createModal(<AnnotationSessionManagerModal 
            escClose={false}
            clickOutsideClose={false}
        />)
    }

    return(<>
        <ControlledTabs  selectedTab={selectedTab} setSelectedTab={setSelectedTab}>
            <div id='Sidebar' ref={props.actionsBarRef} style={{ position: "relative", transition: props.isResizing ? 'none' : `all ${MobergAnimationTiming.REGULAR} ${MobergAnimationCurve.EASE_OUT}`, height: '100%', width: '0px', backgroundColor: '#f8f8f8', transform: 'translate3d(100%,0,0)'}}>
                <div id='SideBarContent' style={{backgroundColor: '#f8f8f8', display: 'none', height: '100%'}}>
                    <Column width="30%" style={{padding: '14px 0px 0px 0px', margin: 'auto', width: 'fit-content'}}>
                        <TabSelector whiteBackground={false} tabKeys={toolbarTabs} style={{color: "#5A6679", height: '45px', backgroundColor: 'rgb(248, 248, 248)'}} />
                    </Column>
                    <div>
                    <div style={{display: 'flex', width: '88%', height: '28px',  marginLeft: '6%', justifyContent: 'space-between'}}>
                        <div style={{flex: 1}} />

                            <MobergRow expand={false} gap="8px" horizontalAlign="right">
                                <MobergDropdown
                                    label={"Write annotations to:"}
                                    options={dropdownOptions}
                                    onChange={handleDropdownChange}
                                    selectedValue={activeAnnotationSession?.id}
                                    size={MobergDropdownSize.SMALL}
                                />

                                <MobergButton onClick={renderAnnotationSessionModal} shape={MobergButtonShape.SQUARE}>
                                    <MdIcons.MdOutlineFolder size={MobergIconSize.LARGE} />
                                </MobergButton>
                            </MobergRow>

                        </div>  
                    <Searchbar className= "ui left icon input">
                        <input type="text" 
                            onChange={event => {setSearchQuery(event.target.value)}}
                            placeholder="Search"
                            onFocus={disableHotKeys}
                            onBlur={enableHotKeys}
                            /> 
                            <i aria-hidden="true" class="search icon"
                            />
                    </Searchbar>
                    </div>
                    <AnnotationTable
                        style={{width: '292px', marginLeft: '13px', marginRight: '13px', marginTop: '15px', position: 'absolute', right: '5%'}}
                        columns={monitoringTableColumns}
                        data={filterUserTableData(monitoringTableData)}
                        onRowClicked={onRowClick}
                        annotations={annotations}
                        />

                </div>
            </div>
        </ControlledTabs>

    </>)
}

const Searchbar = styled.div`
	width: 88%;
	height: 34px;
	background: #FFFFFF;
	border: 0.5px solid #d9d9d9;
	box-sizing: border-box;
	box-shadow: 0px 0px 3px rgba(99, 191, 244, 0.15);
	border-radius: 6px;
	display: inline-flex;
	margin-left: 6%;
    margin-top: 15px;
	overflow-x: hidden;
	overflow-y: hidden;
	flex: 2;
	button{
		width: 80.09px;
		height: 34px;
		background: #207dea;
		border-radius: 0px 6px 6px 0px;
		font-family: 'Source Sans Pro';
		font-style: normal;
		font-weight: 600;
		font-size: 16px;
		line-height: 150%;
		color: #FFFFFF;
		border: none;
		cursor: pointer;
		padding-bottom: 3.5px;
	}
`;

export default ActionsBar;