import { seriesCanvasLine } from "d3fc"
import { TimeSeriesData, getGapIndexes, getTimeSeriesData } from "../../../../Data/TimeSeriesData"
import { LineTraceConfig, PageRectangle } from "../../../../Types/Trace"
import { TraceRenderStrategy, TraceRendererOptions } from "./RenderStrategy"
import { TimeSeriesPageManager } from "../../../../Data/TimeSeriesPageManager"
import { ModalityPage } from "../../../../Data/ModalityPage"
import { D3Trace } from "./D3Trace"
import { TimingInformation } from "../../../../Data/Page"
import { ModalityGraphGroupReactCallbacks } from "../../../../Types/ReactCallbacks"
import { ScaleTime } from "d3"

export class LineRenderStrategy implements TraceRenderStrategy {
	private directRenderer
	private offscreenRenderer
	private pageManager: TimeSeriesPageManager<ModalityPage>
	private config: LineTraceConfig
	private d3Trace: D3Trace
	private renderCacheKey: string = ""
	private dataObjectId: number

	constructor(pageManager: TimeSeriesPageManager<ModalityPage>, d3Trace: D3Trace, config: LineTraceConfig) {
		this.config = config
		this.d3Trace = d3Trace
		this.pageManager = pageManager
		this.dataObjectId = this.d3Trace.getDataObjectId(this.config) ?? Infinity

		this.offscreenRenderer = seriesCanvasLine()
			.mainValue((p: [number, number]) => p[1])
			.crossValue((p: [number, number]) => p[0])

		this.directRenderer = seriesCanvasLine()
			.mainValue((p: [number, number]) => p[1])
			.crossValue((p: [number, number]) => p[0])

		this.updateRenderCacheKey()
	}

	public renderPage(page: ModalityPage, reactCallbacks: ModalityGraphGroupReactCallbacks, offscreenXScale: ScaleTime<any, any, any>, offscreenCanvas: OffscreenCanvas, context: CanvasRenderingContext2D, pageRectangle: PageRectangle) {
		const { x, y, width, height } = pageRectangle
		const dataObjectId = reactCallbacks.dataSourceMap.get(this.config.dataSource) ?? Infinity
		const traceData = page.data.get(dataObjectId)?.get(this.config.dataKey)

		if (traceData !== undefined) {
			offscreenXScale.domain([page.startTime, page.endTime]).range([0, width])

			// If there is no data, still put the cached render in the render cache 
			// so we won't draw a gray rectangle until we reload this page.
			if (traceData.data.length === 0) {
				page.updateRenderCache(dataObjectId, this.renderCacheKey, { bitmap: offscreenCanvas.transferToImageBitmap(), dirty: false, edges: [] })
				return
			}

			const timeSeriesData = getTimeSeriesData(traceData, this.config)
			const edges = this.renderTimeSeriesData(timeSeriesData, page, this.getOffscreenRenderer({ xScale: offscreenXScale, yScale: this.config.yScale }))
			const bitmap = offscreenCanvas.transferToImageBitmap()
			page.updateRenderCache(dataObjectId, this.renderCacheKey, { bitmap, dirty: false, edges })
			context.drawImage(bitmap, x, y)
		} else if (!page.loaded) {
			context.fillStyle = "lightgray"
			context.fillRect(x, y, width, height)
		}

		this.fillInPageGaps()
	}
	
	public getRenderCacheKey(): string {
		return this.renderCacheKey
	}

	private updateRenderCacheKey = () => {
		let compositeIndex = undefined

		if (this.config.isCompositePart) {
			compositeIndex = this.config.compositeIndex
		}

		const { graphId, dataKey, dataSource, color } = this.config as LineTraceConfig
		this.renderCacheKey = `${graphId}-${dataKey}-${compositeIndex}-${dataSource}-${color}`
	}

	public updateConfig(traceConfig: LineTraceConfig) {
		this.config = traceConfig

		this.directRenderer
			.xScale(this.config.xScale)
			.yScale(this.config.yScale)
			.decorate((context: CanvasRenderingContext2D) => {
				context.strokeStyle = this.config.color
			})

		this.offscreenRenderer.decorate((context: CanvasRenderingContext2D) => {
			context.strokeStyle = this.config.color
		})

		this.updateRenderCacheKey()
	}

	public getDirectRenderer = (options?: TraceRendererOptions) => {
		if (options?.xScale) {
			this.directRenderer.xScale(options.xScale)
		}

		if (options?.yScale) {
			this.directRenderer.yScale(options.yScale)
		}

		return this.directRenderer
	}

	public getOffscreenRenderer = (options?: TraceRendererOptions) => {
		if (options?.xScale) {
			this.offscreenRenderer.xScale(options.xScale)
		}

		if (options?.yScale) {
			this.offscreenRenderer.yScale(options.yScale)
		}

		return this.offscreenRenderer
	}

	public render() {
		this.pageManager.getPagesInView().forEach(page => this.d3Trace.renderPage(page))
		this.fillInPageGaps()
	}

	public renderTimeSeriesData(data: TimeSeriesData, page: ModalityPage, renderer: (drawableData: Iterable<[(number | undefined), (number | undefined)]>) => void) {
		// If there is only one data point, just pretend that the value is constant.
		if (data.data.length === 1) {
			data = {
				data: new Float32Array([data.data[0], data.data[0]]),
				times: [page.startTime, page.endTime]
			}
		}
		
		renderer(this.drawableDataGenerator(data, page))

		// The edges can be used to redraw the gap between pages when the underlying data is unavailable
		const edges = [
			[data.times[0], data.data[0]], 
			[data.times[data.times.length - 1], data.data[data.data.length - 1]]
		]

		return edges as [number | undefined, number][]
	}

	public getPageGaps(): Array<(number | undefined)[][] | undefined> {
		const gapFills: Array<(number | undefined)[][] | undefined> = []

		if (this.d3Trace.isRescaling()) {
			return gapFills
		}

		const pagesInView = this.pageManager.getPagesInView()

		// Get the pages
		const pages = [this.pageManager.getPreviousPage(pagesInView[0]), pagesInView[0], pagesInView[1], this.pageManager.getNextPage(pagesInView[1])]

		// For each gap, see if we have the cached edges for both
		pages.forEach((page, index) => {
			if (index === 0) {
				return
			}

			const previousRenderCache = pages[index - 1]?.renderCache.get(this.dataObjectId)?.get(this.renderCacheKey)
			const renderCache = page?.renderCache.get(this.dataObjectId)?.get(this.renderCacheKey)
			const start = previousRenderCache?.edges.at(-1)
			const end = renderCache?.edges.at(0)

			if (start && end) {
				// Get the current sampling period to try and determine gaps
				let samplingPeriod = page?.timingInformation?.get(this.dataObjectId)?.get(this.config.dataKey)?.samplingPeriod

				// If it's unavailable, try to use the previous page sampling period, or default to 0 if it doesn't exist.
				if (!samplingPeriod) {
					samplingPeriod = pages[index-1]?.timingInformation?.get(this.dataObjectId)?.get(this.config.dataKey)?.samplingPeriod ?? 0
				}

				const gapThreshold = 2 * samplingPeriod

				if (end[0] !== undefined && start[0] !== undefined && Math.abs(end[0] - start[0]) <= gapThreshold) {
					// If the gap is small enough to reasonably close for this modality, then close it.
					gapFills.push([start, end])
				} else {
					// If the gap is too large, choose not to fill it.
					gapFills.push([[undefined, 0], [undefined, 0]])
				}
			} else {
				// We need to calculate it
				gapFills.push(undefined)
			}
		})

		// If we have some gaps that are undefined, see if we can fill them with the page data.
		gapFills.forEach((gapFill, index) => {
			if (gapFill !== undefined) {
				return
			}

			const previousPage = pages[index]
			const nextPage = pages[index + 1]

			let previousPageData = previousPage?.data.get(this.dataObjectId)?.get(this.config.dataKey)
			let nextPageData = nextPage?.data.get(this.dataObjectId)?.get(this.config.dataKey)
			const timingInformation = previousPage?.timingInformation.get(this.dataObjectId)?.get(this.config.dataKey)

			if (previousPageData && nextPageData) {
				previousPageData = getTimeSeriesData(previousPageData, this.config)
				nextPageData = getTimeSeriesData(nextPageData, this.config)
				gapFills[index] = this.calculateGapFill(previousPageData, nextPageData, timingInformation)
			}
		})

		return gapFills
	}

	public fillInPageGaps() {
		const gapFills = this.getPageGaps()
		const context = this.directRenderer.context()
		const xScale = this.config.xScale
		const yScale = this.config.yScale

		gapFills.forEach(gapFill => {
			if (!gapFill) {
				return
			}

			// Clear the rectangle that covers the gap
			const startTime = gapFill[0][0]
			const endTime = gapFill[1][0]

			if (!startTime || !endTime) {
				return
			}
			
			const x = xScale(startTime)
			const height = yScale.range()[0]
			const width = xScale(endTime) - x
			context.clearRect(x, 0, width, height)

			// Fill in the gap
			this.directRenderer(gapFill)
		})
	}

	private calculateGapFill(
		firstPageData: TimeSeriesData | undefined, 
		secondPageData: TimeSeriesData | undefined, 
		timingInformation: TimingInformation | undefined, 
	): (number | undefined)[][] {
		if (!firstPageData?.times || !secondPageData?.times) {
			return [[undefined, undefined], [undefined, undefined]] 
		}

		const samplingPeriod = timingInformation?.samplingPeriod ?? Infinity
		const drawStart = [firstPageData?.times?.at(-1), firstPageData?.data?.at(-1)]
		const drawEnd = [secondPageData?.times?.at(0), secondPageData?.data?.at(0)]

		// If the points are too far apart, don't connect them.
		if (drawStart[0] !== undefined && drawEnd[0] !== undefined && Math.abs(drawEnd[0] - drawStart[0]) > samplingPeriod) {
			return [[undefined, undefined], [undefined, undefined]] 
		}

		return [drawStart, drawEnd]
	}

	// We can make this better by using an object pool so we are reusing arrays.
	private *drawableDataGenerator(timeSeriesData: TimeSeriesData, page: ModalityPage): Generator<[number | undefined, number | undefined], void, undefined> {
		const length = timeSeriesData.data.length
		const gapIndexes = getGapIndexes(page.timingInformation.get(this.d3Trace.dataObjectId)?.get(this.config.dataKey)?.gapIndexes ?? [], this.config)

		for (let i = 0; i < length; i++) {
			// Missing values need to be skipped completely
			if (timeSeriesData.data[i] === null || Number.isNaN(timeSeriesData.data[i])) {
				yield [undefined, undefined]
				continue
			}

			if (gapIndexes.includes(i)) {
				yield [undefined, undefined] // This creates a break in the continuous line
			}

			yield [timeSeriesData.times[i], timeSeriesData.data[i]]
		}
	}
}
