import { createContext, useContext, useState, useEffect } from "react";
import { useEndpointProvider } from "./EndpointProvider";
import { LINKS } from './BackendLinks'
import { useStudiesProvider } from "./StudiesProvider";
import { useAuthProvider } from "./AuthProvider";
import { UpdateDispatcher } from "./UpdateDispatcher";


const PermissionsContext = createContext()

export const permissionUpdates = {
    PERMISSIONS: "permissions"
}

export const PermissionsProvider = ({ children }) => {   
    console.log("PROVIDER RENDER: PERMISSIONS")

    const endpointProvider = useEndpointProvider()
    const studiesProvider = useStudiesProvider()
    const authProvider = useAuthProvider()

    const [permissions, setPermissions] = useState([])
    const [permissionGroups, setPermissionGroups] = useState([])

    async function update(...updates) {
        const initState = {
            [permissionUpdates.PERMISSIONS]: permissions
        }

        const dispatcher = new UpdateDispatcher(updates, initState, permissionUpdates)

        dispatcher.dispatch(permissionUpdates.PERMISSIONS,
            () => endpointProvider.post(LINKS.ADMIN.USER_MANAGEMENT.ROLES_AND_PERMISSIONS.GET_PERMISSIONS, 
                {study_id: studiesProvider.selectedStudyId}),
            updatedPermissions => {
                setPermissions(updatedPermissions)
                setPermissionGroups([...new Set(updatedPermissions.map(permission => permission.group))])
            })
    }

    function getGroupPermissions(group) {
        return permissions?.filter(permission => permission.group === group)
    }

    // Make sure we don't persist any data between logins.
    useEffect(() => {
        if (!authProvider.userIsLoggedIn && permissions.length > 0) {
            setPermissions([])
            setPermissionGroups([])
        }
    }, [authProvider, permissions])

    return (
        <PermissionsContext.Provider value={{ permissions, permissionGroups, getGroupPermissions, update }}>
            {children}
        </PermissionsContext.Provider>
    )
}

export const usePermissionsProvider = () => useContext(PermissionsContext)

