import { createContext, useContext, useState, useEffect } from "react";
import { useEndpointProvider } from "./EndpointProvider";
import { LINKS } from './BackendLinks'
import { useStudiesProvider } from "./StudiesProvider";
import { useAuthProvider } from "./AuthProvider";
import { UpdateDispatcher } from "./UpdateDispatcher";


const UsersContext = createContext()

export const userUpdates = {
    USERS_FILTERS: "users_filters"
}

export const UsersProvider = ({ children }) => {   
    console.log("PROVIDER RENDER: USERS")

    const endpointProvider = useEndpointProvider()
    const studiesProvider = useStudiesProvider()
    const authProvider = useAuthProvider()

    const [users, setUsers] = useState([])
    const [filters, setFilters] = useState({})

    async function update(...updates) {
        const initState = {
            // [userUpdates.USERS_FILTERS]: { data: users, filters }
            [userUpdates.USERS_FILTERS]: { data: users}
        }

        const dispatcher = new UpdateDispatcher(updates, initState, userUpdates)

        dispatcher.dispatch(
            userUpdates.USERS_FILTERS,
            () => endpointProvider.post(
                LINKS.ADMIN.USER_MANAGEMENT.USERS.GET_USERS,
                {
                    study_id: studiesProvider.parentStudyID,
                    page_index: 1,
                    rows_per_page: 100, // fetch all
                    order_by: "user_id",
                    order_direction: "desc",
                }
            ),
            data => {
                setUsers(data?.data)
                setFilters(data?.filters)
            }
        )
    }
    
    // Make sure we don't persist any data between logins.
    useEffect(() => {
        if (!authProvider.userIsLoggedIn && (users.length > 0 )) {
            setUsers([])
            setFilters({})
        }
    }, [authProvider, users])

    return (
        <UsersContext.Provider value={{ users, filters, setUsers, update }}>
            {children}
        </UsersContext.Provider>
    )
}

export const useUsersProvider = () => useContext(UsersContext)