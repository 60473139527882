import React from "react"
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil"
import { useModalProvider } from "../../../Providers/ModalProvider"
import { SD_DETECTION_WINDOW_TIME_PRESETS } from "../Viewport/Components/XAxis"

import { editedLayoutGroupAtom, selectedLayoutAtom, selectedLayoutGroupAtom, uneditedLayoutGroupAtom } from "../../../Pages/Data/Visualize/DataReview/Atoms/Layout"
import { SdDetectionConfigAtom } from "../../../Pages/Data/Visualize/DataReview/Atoms/SdDetection"
import { ConfigureSDDetectionModal } from "../../../Pages/Data/Visualize/DataReview/Components/Modals/ConfigureSdDetectionModal"
import { VisualizationToolbar } from "../../../Pages/Data/Visualize/DataReview/Components/React/VisualizationToolbar"
import { DataReviewEEGMontageDropdown } from "../../../Components/EEGMontageDropdownMenu/DataReviewEEGMontageDropdown"
import { MobergDropdown } from "../../../Moberg"

type SDDetectionWindowSettingsProps = {
	windowId: string
}

export function SDDetectionWindowSettings(props: SDDetectionWindowSettingsProps) {
	const { createModal } = useModalProvider()
	const setUneditedLayoutGroup = useSetRecoilState(uneditedLayoutGroupAtom)
	const setEditedLayoutGroup = useSetRecoilState(editedLayoutGroupAtom)
	const selectedLayoutGroup = useRecoilValue(selectedLayoutGroupAtom)
	const selectedLayout = useRecoilValue(selectedLayoutAtom)

	const [sdConfig, setSDConfig] = useRecoilState(SdDetectionConfigAtom({ layoutId: selectedLayout?.id as string, windowId: props.windowId }))
	const [viewStart, viewEnd] = sdConfig.viewScale.domain()
	const viewDuration = viewEnd.getTime() - viewStart.getTime()

	function renderConfigureModal() {
		if (!selectedLayout) {
			console.error("Couldn't render the modal because a layout was not selected")
			return
		}

		setUneditedLayoutGroup(selectedLayoutGroup)
		setEditedLayoutGroup(selectedLayoutGroup)
		createModal(<ConfigureSDDetectionModal windowId={props.windowId} layoutId={selectedLayout.id} />)
	}

	const visualizationSpecificSettings = (<>
		<DataReviewEEGMontageDropdown 
			label={"Montage"}
			currentMontage={sdConfig.montage} 
			updateCurrentMontage={montage => setSDConfig(previous => ({ ...previous, montage }))}
		/>

		<MobergDropdown
			label="Auto-SD-detection"
			selectedValue={sdConfig.autoDetectionEnabled}
			options={[
				{ label: "On", value: true },
				{ label: "Off", value: false }
			]} 
			onChange={autoDetectionEnabled => setSDConfig(previous => ({...previous, autoDetectionEnabled }))}
		/>
	</>)

	return (
		<VisualizationToolbar
			atomValue={sdConfig}
			setAtom={setSDConfig}
			renderConfigureModal={renderConfigureModal}
			viewDuration={viewDuration}
			timePresetOptions={SD_DETECTION_WINDOW_TIME_PRESETS}
			visualizationSpecificSettings={visualizationSpecificSettings}
		/>
	)
}
