import { Socket } from "socket.io-client";
import { Analytic, AutoSDAnalysis } from "../Types/AnalysisDetails";
import { EEGPage } from "./EEGPage";
import { ModalityPageManager } from "./ModalityPageManager";
import { TimeSeriesPageManager, TimeSeriesPageManagerConfig } from "./TimeSeriesPageManager";
import { ModalityPage } from "./ModalityPage";
import { EEGMontage } from "../Types/EEG";

export class SDTimeSeriesPageManager extends TimeSeriesPageManager<EEGPage> {
	public eventDetectionPageManager = new ModalityPageManager() // Keeps track of auto-detected SD events asynchronously from the data.

	public getPageMaker(): () => EEGPage {
		return () => new EEGPage()
	}

	public updateEventDetection(config: TimeSeriesPageManagerConfig, isAutoDetectionEnabled: boolean, eegMontage: EEGMontage | null, montageMapping: Map<string, string>, onPageLoaded: (page: ModalityPage) => void, onPageUnloaded: (page: ModalityPage) => void) {
		const onDemandAnalysis: AutoSDAnalysis = {
			analytic: Analytic.AUTO_SD_DETECTION,
			calculationPeriodMs: 30 * 60 * 1000,
			calculationWindowMs: 30 * 60 * 1000,
			channels: eegMontage?.channels
				.filter(({ first, second }) => montageMapping.has(first) && montageMapping.has(second))
				.map(({ first, second }) => `${montageMapping.get(first)}-${montageMapping.get(second)}`) ?? []
		}

		console.log({ montageMapping, eegMontage, channels: eegMontage?.channels.map(({ first, second }) => `${montageMapping.get(first)}-${montageMapping.get(second)}`) })
		
		const eventDetectionConfig = {
			...config, 
			modalityDataSources: [{
				modality: "EEG,Composite,SampleSeries",
				dataObjectId: config.modalityDataSources[0]?.dataObjectId ?? Infinity,
				onDemandAnalysis
			}] 
		}

		this.eventDetectionPageManager.update(eventDetectionConfig, onPageLoaded, onPageUnloaded)
		console.log("update event detection", isAutoDetectionEnabled)
		this.eventDetectionPageManager.setLoadingEnabled(isAutoDetectionEnabled)
	}

	public clearQueueAndLoad(): void {
		super.clearQueueAndLoad()
		this.eventDetectionPageManager.clearQueueAndLoad()
	}

	public onEndDateUpdated(newEndTime: number): void {
		super.onEndDateUpdated(newEndTime)
		this.eventDetectionPageManager.onEndDateUpdated(newEndTime)
	}

	public unloadAllPages(): void {
		super.unloadAllPages()
		this.eventDetectionPageManager.unloadAllPages()
	}

	public resetPages(): void {
		super.resetPages()
		this.eventDetectionPageManager.resetPages()
	}

	public clearRenderCacheForAllPages(): void {
		super.clearRenderCacheForAllPages()
		this.eventDetectionPageManager.clearRenderCacheForAllPages()
	}

	public setDataQuerySocketAccessor(getDataQuerySocket: (id?: string) => Socket): void {
		super.setDataQuerySocketAccessor(getDataQuerySocket)
		this.eventDetectionPageManager.setDataQuerySocketAccessor(getDataQuerySocket)
	}

	public setLoadingEnabled(enabled: boolean): void {
		super.setLoadingEnabled(enabled)
		this.eventDetectionPageManager.setLoadingEnabled(enabled)
	}
}
