import { LINKS } from "../../../Providers/BackendLinks";

export const ENDPOINTS = {

    GET_ANNOTATION_GROUPS: {
        LINK: LINKS.DATA.PROFILING.GET_ANNOTATION_GROUPS.LINK,
        BODY_FUNCTION:
            (data_object_id) => ({
                data_object_id: data_object_id
            }),
        JSON_RETURN: true
    },
    CREATE_ANNOTATION_GROUP: {
        LINK: LINKS.DATA.PROFILING.CREATE_ANNOTATION_GROUP.LINK,
        BODY_FUNCTION:
            (studyID, patientUD, dataObjectID, groupName) => ({
                study_id: studyID,
                data_object_id: dataObjectID,
                annotation_group_name: groupName
            })
    },
    RENAME_ANNOTATION_GROUP: {
        LINK: LINKS.DATA.PROFILING.RENAME_ANNOTATION_GROUP.LINK,
        BODY_FUNCTION:
            (studyID, patientUD, dataObjectID, annotationGroupID, newGroupName) => ({
                annotation_group_id: annotationGroupID,
                new_group_name: newGroupName
            })
    },
    DELETE_ANNOTATION_GROUP: {
        LINK: LINKS.DATA.PROFILING.DELETE_ANNOTATION_GROUP.LINK,
        BODY_FUNCTION:
            (studyID, patientUD, dataObjectID, annotationGroupID) => ({
                study_id: studyID,
                annotation_group_id: annotationGroupID
            })
    },
    GET_ANNOTATIONS: {
        LINK: LINKS.DATA.PROFILING.GET_ANNOTATIONS.LINK,
        BODY_FUNCTION:
            (studyID, patientUD, dataObjectID, annotationGroupIDs) => ({
                study_id: studyID,
                data_object_id: dataObjectID,
                annotation_group_ids: annotationGroupIDs
            }),
        JSON_RETURN: true
    },
    DELETE_ANNOTATIONS: {
        LINK: LINKS.DATA.PROFILING.DELETE_ANNOTATIONS.LINK,
        BODY_FUNCTION:
            (studyID, patientUD, dataObjectID, annotationIDs) => ({
                study_id: studyID,
                annotation_ids: annotationIDs
            })
    },
    ADD_ANNOTATION: {
        LINK: LINKS.DATA.PROFILING.ADD_ANNOTATION.LINK,
        BODY_FUNCTION:
            (studyID, patientUD, dataObjectID, annotationGroupID, annotation, userEmail) => ({
                study_id: studyID,
                annotation_group_id: annotationGroupID,
                annotation: {
                    ...annotation,
                    user_email: userEmail
                }
            }),
        JSON_RETURN: true
    },
    MODIFY_ANNOTATION: {
        LINK: LINKS.DATA.PROFILING.MODIFY_ANNOTATION.LINK,
        BODY_FUNCTION:
            (studyID, patientUD, dataObjectID, annotationGroupID, annotation) => ({
                study_id: studyID,
                annotation_group_id: annotationGroupID,
                annotation: annotation
            })
    }
}
